<template>
     <div class="modal sizeChart fade" :id="'video'+index" tabindex="-1" aria-labelledby="sizeChart" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" v-if="selected_modal">
            <div class="modal-header p-0">
                <span class="close-modal" data-bs-toggle="modal" data-bs-dismiss="modal"
                    data-bs-target="#sizeChart"><i data-feather="x"></i></span>
            </div>
            <div class="modal-body view-product">
                <div class="slider-box ">
                    <div class="ratio_square  modal-img-height">
                        <div class="swiper mainslider6 ">
                            <div class="swiper-wrapper">

                                <template v-for="(selected_m, index2) in selected_modal" :key="index2">
                                    <div class="swiper-slide">
                                        <img :src="selected_m.imagen" alt="" />
                                    </div>
                                </template>

                            </div>
                            <div class="swiper-button-next"></div>
                            <div class="swiper-button-prev"></div>
                        </div>
                    </div>
                    <div class="thumbnail-box  modal-slider">
                        <div class="swiper thumbnail-img-box thumbnailSlider6">
                            <div class="swiper-wrapper">
                                <template v-for="(selected_m, index2) in selected_modal" :key="index2">
                                    <div class="swiper-slide">
                                        <img :src="selected_m.imagen" alt="" />
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    name:'ImagenProduct',
    data(){
        return {
            selected_modal: null,
        }
    },
    props: ['index'],
    methods:{
    },
    created(){
        
    },
    mounted(){
        this.emitter.on('product-imagenes-modal-'+this.index,data => {
            console.log(data);
            this.selected_modal = null;
            setTimeout(() => {
                this.selected_modal = data;
            }, 50);
            setTimeout(() => {
              const script2 = document.createElement('script');
              script2.src = '../../assets/js/feather/feather.min.js';
              document.body.appendChild(script2);
              script2.onload = () => {
                  console.log('archivo.js cargado 2');
              }
              const detail_product = document.createElement('script');
              detail_product.src = '../../assets/js/myjs/modal.js';
              document.body.appendChild(detail_product);
              detail_product.onload = () => {
                  console.log('');
              }
            }, 100);

        });

    }
}
</script>