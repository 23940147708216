import store from '../plugins/store'
import myApi from "../plugins/axios";
// function getAmountTotal(product){
//     if(product.discount_g && !product.code_offer){
//         if(product.discount_g.discount_id){
//         return product.discount_g.new_amount;
//         }else{
//         if(product.discount_g.type_discount == 1){//%
//             return product.precio_general - (product.precio_general*product.discount_g.discount*0.01);
//         }else{
//             return product.precio_general - product.discount_g.discount;
//         }
//         }
//     }
//     if(product.code_offer){
//         if(product.code_offer.new_amount){
//             return product.code_offer.new_amount;
//             // percentage
//         }else{
//             if(product.code_offer.type_discount == 1){//%
//                 return product.precio_general - (product.precio_general*product.code_offer.discount*0.01);
//             }else{
//                 return product.precio_general - product.code_offer.discount;
//             }
//         }
//     }
//     return product.precio_general;
// }
function getAmountTotal(product){
    if(product.discount_g && !product.code_offer){
        if(product.discount_g.discount_id || product.discount_g.kit_id){
            return Number(product.discount_g.new_amount.toFixed(2));
            // percentage
        }else{
            if(product.discount_g.type_discount == 1){//%
                return Number((product.precio_general - (product.precio_general*product.discount_g.discount*0.01)).toFixed(2));
            }else{
                return Number((product.precio_general - product.discount_g.discount).toFixed(2));
            }
        }
    }
    if(product.code_offer){
        if(product.code_offer.new_amount){
            return Number(product.code_offer.new_amount.toFixed(2));
            // percentage
        }else{
        if(product.code_offer.type_discount == 1){//%
                return Number((product.precio_general - (product.precio_general*product.code_offer.discount*0.01)).toFixed(2));
            }else{
                return Number((product.precio_general - product.code_offer.discount).toFixed(2));
            }
        }
    }
    return product.precio_general;
}
function getTypeDiscount(product){
    if(product.discount_g && !product.code_offer){
        if(product.discount_g.discount_id  || product.discount_g.kit_id){
            return 1;
        }else{
            return product.discount_g.type_discount;
        }
    }
    if(product.code_offer){
        if(product.code_offer.new_amount){
            return 1;
        }else{
            return product.code_offer.type_discount;
        }
    }
    return null;
}
function getAmountDiscount(product){
    if(product.discount_g){
        if(product.discount_g.discount_id || product.discount_g.kit_id){
            return product.discount_g.percentage;
        }else{
        if(product.discount_g.type_discount == 1){//%
            return product.discount_g.discount;
        }else{
            return product.discount_g.discount;
        }
        }
    }
    if(product.code_offer){
        if(product.code_offer.new_amount){
            return product.code_offer.percentage;
        }else{
        if(product.code_offer.type_discount == 1){//%
            return product.code_offer.discount;
        }else{
            return product.code_offer.discount;
        }
        }
    }
    return 0;
}

function register_guest (PRODUCT, quantity = 1) {
    return {
        title: PRODUCT.title ,
        imagen: PRODUCT.image,
        uniqd: PRODUCT.uniqd ,
        slug: PRODUCT.slug ,
        avg_rating: PRODUCT.avg_rating,
        reviews_count: PRODUCT.reviews_count,
        quantity: quantity,
        is_kit: PRODUCT.is_kit,
        kit_id: PRODUCT.kit_id,
        price_unit: PRODUCT.precio_general_old ? PRODUCT.precio_general_old : PRODUCT.precio_general,
        is_pc_build: PRODUCT.is_pc_build ? 1 : null,
        subtotal:(PRODUCT.precio_general_old ? PRODUCT.precio_general : getAmountTotal(PRODUCT)),
        total:(PRODUCT.precio_general_old ? PRODUCT.precio_general  : getAmountTotal(PRODUCT))  * quantity,
        type_discount: PRODUCT.precio_general_old && PRODUCT.type_discount? PRODUCT.type_discount : getTypeDiscount(PRODUCT),
        discount: PRODUCT.precio_general_old && PRODUCT.discount? PRODUCT.discount : getAmountDiscount(PRODUCT),
        code_cupon: null,
        code_discount: PRODUCT.discount_g ? PRODUCT.discount_g.uniqd : null,
        code_offer: PRODUCT.discount_g ? PRODUCT.discount_g.uniqd : null,
        sucursales: PRODUCT.sucursales,
        type_discount_old: getTypeDiscount(PRODUCT),
        discount_old: getAmountDiscount(PRODUCT),
    }
}

function register_client (PRODUCT, quantity = 1) {
    return {
        user_id: PRODUCT.user_id,
        title: PRODUCT.title ,
        imagen: PRODUCT.image,
        product_id: PRODUCT.product_id ? PRODUCT.product_id : PRODUCT.id,
        uniqd: PRODUCT.uniqd,
        slug: PRODUCT.slug ,
        quantity: quantity,
        avg_rating: PRODUCT.avg_rating,
        reviews_count: PRODUCT.reviews_count,
        price_unit: PRODUCT.precio_general_old ? PRODUCT.precio_general_old : PRODUCT.precio_general,
        is_pc_build: PRODUCT.is_pc_build ? 1 : null,
        is_kit: PRODUCT.is_kit,
        kit_id: PRODUCT.kit_id,
        subtotal:(PRODUCT.precio_general_old ? PRODUCT.precio_general : getAmountTotal(PRODUCT)),
        total:(PRODUCT.precio_general_old ? PRODUCT.precio_general  : getAmountTotal(PRODUCT))  * quantity,
        type_discount: PRODUCT.precio_general_old && PRODUCT.type_discount ? PRODUCT.type_discount : getTypeDiscount(PRODUCT),
        discount: PRODUCT.precio_general_old && PRODUCT.discount ? PRODUCT.discount : getAmountDiscount(PRODUCT),
        code_cupon: null,
        code_discount: PRODUCT.discount_g ? PRODUCT.discount_g.uniqd : null,
        code_offer: PRODUCT.discount_g ? PRODUCT.discount_g.uniqd : null,
        sucursales: PRODUCT.sucursales,
        type_discount_old: getTypeDiscount(PRODUCT),
        discount_old: getAmountDiscount(PRODUCT),
    }
}

function update (CART) {
    return {
        cart_id: CART.id,
        user_id: CART.user_id,
        title: CART.product.title ,
        imagen: CART.product.image,
        product_id: CART.product_id,
        uniqd: CART.product.uniqd,
        slug: CART.product.slug ,
        quantity: CART.quantity,
        avg_rating: CART.product.avg_rating,
        reviews_count: CART.product.reviews_count,
        price_unit: CART.price_unit,
        is_pc_build: CART.is_pc_build,
        kit_id: CART.kit_id,
        is_kit: CART.is_kit,
        subtotal: CART.subtotal,
        total: CART.total,
        type_discount: CART.type_discount,
        discount: CART.discount,
        code_cupon: CART.code_cupon,
        code_discount: CART.code_discount,
        code_offer: CART.code_discount,
        product: CART.product,
        sucursales: CART.product.sucursales,
        id: CART.id,
        type_discount_old: CART.type_discount_old,
        discount_old: CART.discount_old,
    }
}

export default {
    register_guest: (PRODUCT) => {
       return register_guest(PRODUCT);
    },
    register_client: (PRODUCT, quantity = 1) => {
        return register_client(PRODUCT, quantity);
    },
    update: (CART) => {
        return update(CART);
    },
    add: async (CARTS,PRODUCTY,emitter,notify,cookies,quantity=1) => {
        let INDEX = CARTS.findIndex(product => product.uniqd == PRODUCTY.uniqd);
        

        // let errors = [];
        // // let IS_EXISTENCIA = false;
        let COUNT_CART = quantity;
        if(INDEX != -1){
            COUNT_CART = INDEX != -1 ? CARTS[INDEX].quantity : 0;
            COUNT_CART = COUNT_CART + quantity;
        }
        // // console.log(PRODUCTY);
        // if(PRODUCTY.sucursales){
        //     PRODUCTY.sucursales.every((sucursal) => {
        //         if(parseInt(sucursal.stock) < (quantity + COUNT_CART)){
        //             errors.push("LA CANTIDAD SOLICITADA NO SE ENCUENTRA DISPONIBLE EN NINGUNA SUCURSAL");
        //             // return false;
        //         }
        //         // else{
        //         //     IS_EXISTENCIA = true;
        //         // }
        //         return true;
        //     });
        // }
        // if(PRODUCTY.sucursales && errors.length ==  PRODUCTY.sucursales.length){//&& !IS_EXISTENCIA
        //     // notify({
        //     //     title: "VALIDACIÓN DE EXISTENCIA",
        //     //     text: "LA CANTIDAD SOLICITADA NO SE ENCUENTRA DISPONIBLE EN NINGUNA SUCURSAL",
        //     //     type:'error',
        //     //     duration: 1000,
        //     // });
        //     emitter.emit("add-error-cart-shop", "La cantidad de producto que deseas no se encuentra disponible en este momento. Te sugerimos intentar con una cantidad diferente o ponerte en contacto con servicio al cliente para obtener una solución");
        //     // errors.forEach((err) => {
        //     // })
        //     return;
        // }
        // if(!store.getters.loggedIn){
            
        // }
        try {
            const resp = await myApi.post(
                "ecommerce/valid_carts_add_cart/",{
                    uniqd_product: PRODUCTY.uniqd,
                    quantity: COUNT_CART,
                    cart_id: INDEX != -1 ? CARTS[INDEX].cart_id : null
                }
            );
            console.log(resp);
            if(resp.data.message == 403){
                notify({
                    title: resp.data.message_text,
                    duration: 3500,
                    type: 'error'
                });
            }else{
                if(!store.getters.loggedIn){
                    let data = register_guest(PRODUCTY,quantity);
                    if(INDEX != -1){
                      CARTS[INDEX].quantity = CARTS[INDEX].quantity + quantity;
                      CARTS[INDEX].total =  (CARTS[INDEX].subtotal * CARTS[INDEX].quantity).toFixed(2);
                    }else{
                        if(CARTS.length == 3){
                            notify({
                                title: 'Para agregar más productos al carrito inicia sesión o crea una cuenta.',
                                duration: 3500,
                                type: 'error'
                            });
                            return;
                        }
                        CARTS.push(data);
                    }
                    console.log(cookies);
                    // cookies.set('carts',CARTS);
                    localStorage.setItem('carts',JSON.stringify(CARTS));
                    emitter.emit("cart-shop", CARTS);
                     notify({
                        title: "Producto agregado al carrito",
                      //   text: `Tu producto '${PRODUCTY.title}' se agrego al carrito correctamente!`,
                        duration: 3500,
                        type: 'success',
                    });
                }else{
                    var data = {};
                    if(resp.data.is_delete == 2){
                        INDEX = -1;
                    }
                    if(INDEX != -1){
                        CARTS[INDEX].quantity = CARTS[INDEX].quantity + quantity;
                        CARTS[INDEX].total =  (CARTS[INDEX].subtotal * CARTS[INDEX].quantity).toFixed(2);
                        data = update(CARTS[INDEX]);
                    }else{
                        PRODUCTY.user_id = store.state.user.id;
                        data = register_client(PRODUCTY,quantity);
                    }
                    emitter.emit('save-cart', data);
                }
            }
        } catch (error) {
            console.log(error);
            notify({
                title: "Hubo un problema al procesar el producto . Intente nuevamente :)",
                duration: 3500,
                type: 'success'
            });
        }

        
    },
    addDinamic: (CARTS,PRODUCTY,emitter,notify,cookies,quantity=1) => {
        let INDEX = CARTS.findIndex(product => product.uniqd == PRODUCTY.uniqd);
        

        let errors = [];
        // let IS_EXISTENCIA = false;
        // let COUNT_CART = INDEX != -1 ? CARTS[INDEX].quantity : 0;
        // console.log(PRODUCTY);
        if(PRODUCTY.sucursales){
            PRODUCTY.sucursales.every((sucursal) => {
                if(parseInt(sucursal.stock) < (quantity)){
                    errors.push("La cantidad solicitada no se encuentra disponible en ninguna sucursal");
                    // return false;
                }
                // else{
                //     IS_EXISTENCIA = true;
                // }
                return true;
            });
        }
        if(PRODUCTY.sucursales && errors.length ==  PRODUCTY.sucursales.length){//&& !IS_EXISTENCIA
            // notify({
            //     title: "VALIDACIÓN DE EXISTENCIA",
            //     text: "LA CANTIDAD SOLICITADA NO SE ENCUENTRA DISPONIBLE EN NINGUNA SUCURSAL",
            //     type:'error',
            //     duration: 1000,
            // });
            emitter.emit(
                "add-error-cart-shop", "La cantidad de producto que deseas no se encuentra disponible en este momento. Te sugerimos intentar con una cantidad diferente o ponerte en contacto con servicio al cliente para obtener una solución"
            );
            // errors.forEach((err) => {
            // })
            return;
        }

        console.log(quantity,INDEX);
        if(!store.getters.loggedIn){
          let data = register_guest(PRODUCTY);
          if(INDEX != -1){
            CARTS[INDEX].quantity = quantity;//CARTS[INDEX].quantity + 
            CARTS[INDEX].total =  (CARTS[INDEX].subtotal * CARTS[INDEX].quantity).toFixed(2);
          }else{
              CARTS.push(data);
          }
          console.log(cookies);
        //   cookies.set('carts',CARTS);
        localStorage.setItem('carts',JSON.stringify(CARTS));
          emitter.emit("cart-shop", CARTS);
           notify({
              title: "Producto agregado al carrito",
            //   text: `Tu producto '${PRODUCTY.title}' se agrego al carrito correctamente!`,
              duration: 3500,
              type: 'success'
          });
        }else{
          var data = {};
          if(INDEX != -1){
            CARTS[INDEX].quantity = quantity;//CARTS[INDEX].quantity + 
            CARTS[INDEX].total =  (CARTS[INDEX].subtotal * CARTS[INDEX].quantity).toFixed(2);
            data = update(CARTS[INDEX]);
          }else{
            PRODUCTY.user_id = store.state.user.id;
            data = register_client(PRODUCTY,quantity);
          }
          emitter.emit('save-cart', data);
        }
    },
    deleteCoupon: (CARTS,COUPON,emitter,notify,cookies) => {
        if(!store.getters.loggedIn){
            CARTS = CARTS.map((cart) => {
                if(cart.code_cupon == COUPON){
                    if(cart.code_offer){
                        cart.code_cupon = null;
                        cart.code_discount = cart.code_offer;
                        cart.discount = cart.discount_old;
                        cart.type_discount = cart.type_discount_old;

                        let subtotal = 0;
                        if(cart.type_discount == 1){//%
                            subtotal = Number((cart.price_unit - (cart.price_unit*cart.discount*0.01)).toFixed(2));
                        }else{
                            subtotal = Number((cart.price_unit - cart.discount).toFixed(2));
                        }

                        cart.subtotal = subtotal;
                        cart.total =  Number((cart.subtotal*cart.quantity).toFixed(2));
                    }else{
                        cart.code_cupon = null;
                        cart.discount = 0;
                        cart.type_discount = null;
                        cart.subtotal = cart.price_unit;
                        cart.total = cart.subtotal*cart.quantity;
                    }
                }
                return cart;
            });
            console.log(cookies);
            // cookies.set('carts',CARTS);
            localStorage.setItem('carts',JSON.stringify(CARTS));
            emitter.emit("cart-shop", CARTS);
            notify({
                title: "Carrito de compra",
                text: `A tu carrito se le quito el cupon '${COUPON}' correctamente!`,
                duration: 3500,
                type: 'success'
            });
        }else{
            CARTS = CARTS.map((cart) => {
                if(cart.code_cupon == COUPON){
                    cart.code_cupon = null;
                    cart.discount = 0;
                    cart.type_discount = null;
                    cart.subtotal = cart.price_unit;
                    cart.total = cart.subtotal*cart.quantity;
                }
                return cart;
            });
            setTimeout(() => {
                emitter.emit('update-carts', CARTS);
            }, 25);
        }
    },
    getAmountTotalProduct: (product) => {
        product.precio_general = Number(product.precio_general);
        return getAmountTotal(product);
    }
}