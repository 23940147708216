<template>
    <div class="modal view-product fade" id="viewModal" tabindex="-1" aria-labelledby="viewModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" v-if="product_selected_modal">
          <div class="modal-body">
            <span class="close-modal d-none d-md-block" data-bs-toggle="modal" data-bs-target="#viewModal"><i data-feather="x"></i></span>
            <section class="product-page">
              <div class="view-product ">
                  <div class="container-lg">
                    <div class="row g-3 g-xl-4">
                      <div class="col-md-6">
                        <div class="slider-box sticky off-50 position-sticky">
                          <span class="close-modal d-md-none" data-bs-toggle="modal" data-bs-target="#viewModal"><i data-feather="x"></i></span>
                          <div class="row g-2">
                            <div class="col-2">
                              <div class="thumbnail-box">
                                <div class="swiper thumbnail-img-box thumbnailSlider">
                                  <div class="swiper-wrapper">
                                    <template v-for="(imagen,index) in product_selected_modal.images" :key="index">
                                        <div class="swiper-slide">
                                            <img v-bind:src="imagen.imagen" alt="img" />
                                        </div>
                                    </template>
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-10 ratio_square">
                              <div class="swiper mainslider">
                                <div class="swiper-wrapper">
                                    <template v-for="(imagen,index) in product_selected_modal.images" :key="index">
                                        <div class="swiper-slide">
                                            <img class="bg-img" v-bind:src="imagen.imagen" alt="img" />
                                        </div>
                                    </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="product-detail-box">
                          <div class="product-option">
                            <h1>{{product_selected_modal.title}}</h1>
                            <div class="option price">
                              <h5>Precio</h5>
                              <span> $ {{ product_selected_modal.precio_general }}  
                                <!-- <del>$300.00</del> -->
                              </span> 
                            </div>
                            <div class="option" v-if="product_selected_modal.summary">
                              <h5> Descripción: </h5>
                              <p class="content-color">{{ product_selected_modal.summary }}</p>
                            </div>
                            <div class="btn-group">
                              <div class="plus-minus">
                                <i class="sub" data-feather="minus"></i>
                                <input type="number" v-model="cantidad" min="1" max="10" />
                                <i class="add" data-feather="plus"></i>
                              </div>

                              <a href="javascript:void(0)" @click="addCart()" class="btn-solid btn-sm addtocart-btn">Agregar al carrito </a>
                              <a href="javascript:void(0)" @click="addBuy()" data-bs-dismiss="modal" aria-label="Close" class="btn-outline btn-sm wishlist-btn">Comprar
                                ahora</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </section>

            <section class="tab-especificaciones">
                <div class="container-lg" >
                    <div class="title-box4" ></div>
                    <div class="description-box" >
                        <div class="row gy-4" >
                            <div class="col-12" >
                            <ul class="nav nav-pills nav-tabs2 row-tab" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation"><button class="nav-link active" id="description-tab" data-bs-toggle="pill" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true"> Descripción </button></li>
                                <li class="nav-item" role="presentation"><button class="nav-link" id="specification-tab" data-bs-toggle="pill" data-bs-target="#specification" type="button" role="tab" aria-controls="specification" aria-selected="false"> Especificaciones </button></li>
                            </ul>
                            </div>
                            <div class="col-12" >
                            <div class="tab-content d-block" id="pills-tabContent" >
                                <div class="tab-pane fade active show" id="description" role="tabpanel" aria-labelledby="description-tab" >
                                    <div class="details-product" >
                                        <div v-html="product_selected_modal.description">
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="specification" role="tabpanel" aria-labelledby="specification-tab" >
                                    <div class="specification-wrap" >
                                        <div class="table-responsive">
                                            <table
                                            class="specification-table table striped"
                                            v-if="product_selected"
                                            >
                                            <template
                                                v-for="(attribute, index) in this.product_selected
                                                .attributes"
                                                :key="index"
                                            >
                                                <tr>
                                                <th>
                                                    {{
                                                    attribute.attribute_categorie.attribute.name
                                                    }}
                                                </th>
                                                <td>
                                                    {{
                                                    getAttributeN(attribute)
                                                    }}
                                                </td>
                                                </tr>
                                            </template>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


          </div>
        </div>
      </div>
    </div>
</template>

<script>
import CartResource from '../../../resource/cart'
export default {
    name: 'ModalDetailProduct',
    data(){
        return {
            cantidad: 1,
            product_selected_modal: null,
            CARTS: [],
        }
    },
    methods:{
        addCart(){
            let CARTS = this.CARTS;//this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
            // console.log(CARTS);
            CartResource.add(CARTS,this.product_selected_modal,this.emitter, this.$notify,this.$cookies,this.cantidad);
            // let CARTS = this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
            // // console.log(CARTS);
            // let INDEX = CARTS.findIndex(product => product.uniqd == this.product_selected_modal.uniqd);
            
            // if(INDEX != -1){
            //     CARTS[INDEX].quantity = CARTS[INDEX].quantity + this.cantidad;
            //     CARTS[INDEX].total =  CARTS[INDEX].subtotal * CARTS[INDEX].quantity;
            // }else{
            //     CARTS.push({
            //         title: this.product_selected_modal.title ,
            //         imagen: this.product_selected_modal.image,
            //         uniqd: this.product_selected_modal.uniqd ,
            //         slug: this.product_selected_modal.slug ,
            //         quantity: this.cantidad,
            //         price_unit: Math.round(this.product_selected_modal.precio_general),
            //         subtotal: Math.round(this.product_selected_modal.precio_general),
            //         total: Math.round(this.product_selected_modal.precio_general) * this.cantidad,
            //         type_discount: null,
            //         discount: null,
            //         code_cupon: null,
            //         code_discount: null,
            //     });
            // }

            // this.$cookies.set('carts',CARTS);

            // this.emitter.emit('cart-shop', CARTS);

            // this.$notify({
            //     title: "CARRITO DE COMPRA",
            //     text: `TU PRODUCTO '${this.product_selected_modal.title}' SE AGREGO AL CARRITO CORRECTAMENTE!`,
            //     duration: 1000,
            // });
        },
        addBuy(){
          this.addCart();
          setTimeout(() => {
            this.$router.push({
              name: "Checkout"
            });
          }, 100);
        },
    },
    created(){
        // console.log(this.product_selected_modal);
    },
    mounted(){
        this.emitter.on('product-detail-modal',data => {
            this.product_selected_modal = data;
            setTimeout(() => {
              const script2 = document.createElement('script');
              script2.src = '../../assets/js/feather/feather.min.js';
              document.body.appendChild(script2);
              script2.onload = () => {
                  console.log('archivo.js cargado 2');
              }
  
              const detail_product = document.createElement('script');
              detail_product.src = '../../assets/js/myjs/modal.js';
              document.body.appendChild(detail_product);
              detail_product.onload = () => {
                  console.log('');
              }
            }, 50);

        });

        this.emitter.on('cart-shop',data => {
            this.CARTS = data;
        });
        this.emitter.emit("cart-list", {});
    },
}
</script>