<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <div class="breadcrumb-wrap">
                <!-- <div class="banner">
                    <img class="bg-img bg-top" src="/assets/images/inner-page/banner-p.jpg" alt="banner" /> -->
                <div class="banner2" style="background: url('/assets/images/bg/bg-header-001.jpg'); background-color:blue">
                    <div class="container-lg">
                        <div class="breadcrumb-box">
                        <div class="heading-box">
                            <h1> Tienda</h1>
                        </div>
                        <ol class="breadcrumb">
                            <li><a href="/">Inicio</a></li>
                            <li>
                            <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                            </li>
                            <li class="current"><a href="javascript:void(0)">Filtro</a></li>
                        </ol>
                        </div>
                    </div>
                </div>

            </div>
            <!-- Breadcrumb End -->
            <FilterProduct :slug_product="''" :is_tienda="1"></FilterProduct>
        </main>
        <!-- Main End -->

    </div>
</template>
<script>
import FilterProduct from './layouts/FilterProduct.vue'
// import myApi from '../../plugins/axios'
import { useMeta } from 'vue-meta'

export default {
    name: 'FilterTienda',
    components:{
        FilterProduct,
    },
    data(){
        return {
            slug_product: null,
        }
    },
    methods: {
    },
    created(){
        // this.slug_product = this.$route.query.text;
        // console.log(this.slug_product)
    },
    mounted(){
        this.useMetaT = useMeta({
            title: 'Tienda',
            htmlAttrs: { lang: 'en', amp: true },
            meta: [],
        });
    }
}
</script>