<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <div class="breadcrumb-wrap">
                <div class="banner">
                <img class="bg-img bg-top" src="/assets/images/inner-page/banner-p.jpg" alt="banner" />

                <div class="container-lg">
                    <div class="breadcrumb-box">
                    <div class="heading-box">
                        <h1>Mi Lista de deseo</h1>
                    </div>
                    <ol class="breadcrumb">
                        <li><a href="/">Inicio</a></li>

                        <li>
                        <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                        </li>

                        <li class="current"><a href="javascript:void(0)">Lista de deseo</a></li>
                    </ol>
                    </div>
                </div>
                </div>
            </div>
            <!-- Breadcrumb End -->

            <!-- Wishlist Section Start -->
            <section class="section-b-space wishlist-page">
                <div class="container-lg">
                <div class="row g-3 g-3 g-xxl-4 ratio_asos row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6">
                    <template v-for="(WISH, index) in WISHLIST" :key="index">
                        <div class="col">
                            <div class="product-card product-wishlist">
                                <div class="img-box">
                                <router-link :to="'/product/'+WISH.slug" class="primary-img">
                                    <img class="img-fluid bg-img" v-bind:src="WISH.imagen" alt="product" /> 
                                </router-link>
                                <!-- Rating -->
                                <div class="rating">
                                    <span>{{ WISH.avg_rating }} <i data-feather="star"></i> </span>
                                    <span>{{ WISH.reviews_count }}</span>
                                </div>

                                <!-- Delete Button -->
                                <button class="delete-button" @click="removeWish(WISH)"><i data-feather="x"></i></button>
                                </div>
                                <div class="content-wrap">
                                <!-- Content Box -->
                                <router-link :to="'/product/'+WISH.slug">
                                    <p>{{ WISH.categorie_first.name }}</p>
                                    <h5>{{  WISH.title.length > 15 ?  WISH.title.slice(0, 15)+"..." :  WISH.title }}</h5>
                                    <span>${{ WISH.precio_general }}</span> 
                                </router-link>
                                <div class="mov-to-bag">
                                    <a href="javascript:void(0)" @click="addCart(WISH)" class="btn btn-outline btn-sm">Añadir al Carrito <i class="arrow"></i> </a>
                                </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="col-12" v-if="WISHLIST.length == 0">
                        <div class="not-found text-center" style="width: 100%;">
                            <p>¡Ups! No he encontrado resultados<br> pero hay mucho más, busca algo nuevo.</p>
                            <img src="/assets/images/product/zeta-notfound.svg">
                            <router-link class="py-4" to="/">Ir a Inicio</router-link>
                        </div>
                    </div>
                </div>
                </div>
            </section>
            <!-- Wishlist Section End -->
        </main>
        <!-- Main End -->
    </div>
</template>
<script>
import CartResource from '../../resource/cart'
import store from '../../plugins/store'
import myApi from '../../plugins/axios'
import { useMeta } from 'vue-meta'

export default {
    name: 'WishList',
    data(){
        return {
            WISHLIST:[],
            CARTS: [],
        }
    },
    methods:{
        async addCart(WISH){
            let CARTS = this.CARTS;//this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
            // WISH.product_id = WISH.product.id;
            const resp = await myApi.get(
            "ecommerce/products_dev/"+WISH.product_id);
            console.log(resp);
            CartResource.add(CARTS,resp.data.product,this.emitter, this.$notify,this.$cookies);
            
        },
        removeWish(WISH){
            let INDEX = this.WISHLIST.findIndex(product => product.uniqd == WISH.uniqd);
            
            // if(INDEX != -1){
            //   this.WISHLIST.splice(INDEX,1);
            // }

            // this.$cookies.set('wishlist',this.WISHLIST);
            // this.emitter.emit('wish-list-product', this.WISHLIST);

            // this.$notify({
            //     title: "LISTA DE DESEOS",
            //     text: `TU PRODUCTO '${WISH.title}' SE ELIMINO DE LA LISTA DE DESEO!`,
            //     duration: 3000,
            //     type:'warn'
            // });
             if(!store.getters.loggedIn){
                if(INDEX != -1){
                    this.WISHLIST.splice(INDEX,1);
                }
                this.$cookies.set('wishlist',this.WISHLIST);
                this.$notify({
                    title: "Producto removido de lista de deseos",
                    // text: `Tu producto '${WISH.title}' se eliminó de la lista de deseo!`,
                    duration: 3000,
                    type:'error'
                });
            }else{
                if(INDEX != -1){
                    this.deleteWish(this.WISHLIST[INDEX],INDEX);
                }
            }
            this.emitter.emit('wish-list-product', this.WISHLIST);
        },
        async deleteWish(data,index){
            try {
                const resp = await myApi.delete(
                    "ecommerce/wishlists/"+data.id
                );
                console.log(resp);
                if(index != -1){
                    this.WISHLIST.splice(index,1);
                }
                this.$notify({
                    title: "Producto removido de lista de deseos",
                    // text: `Tu producto '${data.title}' se eliminó de la lista de deseo!`,
                    duration: 3000,
                    type:'error'
                });
            } catch (error) {
                console.log(error);
            }
        },
    },
    created(){

    },
    mounted(){
        this.useMetaT = useMeta({
            title: 'Mi Lista de deseo',
            htmlAttrs: { lang: 'en', amp: true },
            meta: [],
        });
        let WISHLIST = this.$cookies.get("wishlist") ? this.$cookies.get("wishlist") : [];
        this.WISHLIST = WISHLIST;
        console.log(this.WISHLIST);
        this.emitter.on('wish-list-product',data => {
         this.WISHLIST = data;
         console.log(this.WISHLIST);
         const script2 = document.createElement('script');
            script2.src = '../../assets/js/feather/feather.min.js';
            document.body.appendChild(script2);
            script2.onload = () => {
            console.log('archivo.js cargado 2');
            }
        });
        this.emitter.on('cart-shop',data => {
         this.CARTS = data;
        });
        this.emitter.emit("cart-list", {});
        this.emitter.emit("wish-list-list", {});
    },
}
// let CARTS = this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
// // console.log(CARTS);
// let INDEX = CARTS.findIndex(product => product.uniqd == WISH.uniqd);

// if(INDEX != -1){
//     CARTS[INDEX].quantity = CARTS[INDEX].quantity + 1;
//     CARTS[INDEX].total =  CARTS[INDEX].subtotal * CARTS[INDEX].quantity;
// }else{
//     CARTS.push({
//         title: WISH.title ,
//         imagen: WISH.imagen,
//         uniqd: WISH.uniqd ,
//         slug: WISH.slug ,
//         quantity: 1,
//         price_unit: Math.round(WISH.precio_general),
//         subtotal: Math.round(WISH.precio_general),
//         total: Math.round(WISH.precio_general) * 1,
//         type_discount: null,
//         discount: null,
//         code_cupon: null,
//         code_discount: null,
//     });
// }

// this.$cookies.set('carts',CARTS);

// this.emitter.emit('cart-shop', CARTS);

// this.$notify({
//     title: "CARRITO DE COMPRA",
//     text: `TU PRODUCTO '${WISH.title}' SE AGREGO AL CARRITO CORRECTAMENTE!`,
//     duration: 3000,
// });
</script>