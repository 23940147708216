<template>
    <div>
        <div class="product-card  option-bottom" v-if="product && type_card">
            <div class="img-box">
                <a v-if="!is_not_detail" href="javascript:void(0)" :data-url="'/kits/'+product.slug+getUrlDiscount(product)"  class="primary-img slide-link">
                    <ProgressiveImage 
                        placeholder-src="/assets/svg_load/image-preloader.svg"
                        v-if="product.image" class="img-fluid"  :src="product.image" />
                </a>

                <span v-if="is_not_detail" @click="toUrlProduct('/kits/'+product.slug+getUrlDiscount(product))" class="primary-img slide-link" style="cursor: pointer;">
                    <ProgressiveImage 
                        placeholder-src="/assets/svg_load/image-preloader.svg"
                        v-if="product.image" class="img-fluid"  :src="product.image" />
                </span>

                <span class="arrow-label-wrap" v-if="!product.discount_g && !product.code_offer && getTagNew(product)"> 
                    <span class="arrow-label bg-success"> {{ getNameTagNew(product) }}</span> 
                </span>

                <span class="arrow-label-wrap"  v-if="product.color_tag && !product.discount_g && !product.code_offer && !getTagNew(product)"> <span class="arrow-label bg-primary p-1" 
                    :style="{'background-color': product.color_tag+' !important'}" style="width: 100%;"> {{ product.name_tag }}</span> 
                </span>

                <span class="arrow-label-wrap"  v-if="product.discount_g && (typeof product.discount_g.discount === 'string' || product.discount_g.discount instanceof String) && !product.code_offer"> <span class="arrow-label bg-primary p-1" 
                    :style="{'background-color': product.discount_g.color_tag+'  !important'}" style="width: 100%;"> {{ product.discount_g.title }} - {{ getDiscountPrice(product) }}</span> 
                </span>
                
                <span class="arrow-label-wrap"  v-if="product.discount_g && !(typeof product.discount_g.discount === 'string' || product.discount_g.discount instanceof String) && !product.code_offer"> <span class="arrow-label bg-primary p-1" 
                    :style="{'background-color': product.discount_g.discount.color_tag+'  !important'}" style="width: 100%;"> {{ product.discount_g.discount.title }} - {{ getDiscountPrice(product) }}</span> 
                </span>

                <span class="arrow-label-wrap"  v-if="product.code_offer"> <span class="arrow-label bg-primary p-1" 
                    :style="{'background-color': product.code_offer.color_tag+'  !important'}" style="width: 100%;"> Descuento - {{ getOfferPrice(product) }}</span> 
                </span>

                <ul class="option-wrap">

                    <template v-if="!pc_build_attribute">
                        <!-- <li data-bs-toggle="tooltip" data-bs-placement="left" title="Lista Deseos" @click="addWishList(product)">
                            <span href="javascript:void(0)" class="">
                            <img src="/assets/images/Nuevos/favorites-icon.webp">
                            </span>
                        </li> -->
                        <!-- <li data-bs-toggle="tooltip" data-bs-placement="left" title="Comparar">
                            <span href="javascript:void(0)" @click="goToCompare(product)">
                            <img src="/assets/images/Nuevos/compare-icon.webp">
                            </span>
                        </li> -->
                        <li data-bs-toggle="tooltip" data-bs-placement="left" title="Ver Producto">
                            <span href="javascript:void(0)" @click="openDetailProduct(product)" data-bs-toggle="modal" data-bs-target="#viewModal">
                                <img src="/assets/images/Nuevos/search-icon.webp">
                            </span>
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-placement="left" title="My Cart">
                            <span href="javascript:void(0)" @click="addCart(product)" class="">
                            <img src="/assets/images/Nuevos/cart-icon.webp">
                            </span>
                        </li>
                    </template>

                    <template v-if="pc_build_attribute">
                        <button class="btn btn-primary btn-sm" @click="pcBuildSelectedProduct(product)">Agregar</button>
                    </template>
                </ul>
            </div>
            <div class="content-box">
                <span @click="toUrlProduct('/kits/'+product.slug+getUrlDiscount(product))" style="cursor: pointer;">
                    <p><span
                        style="cursor: pointer;"
                         @click="toUrlProduct('/shop/brands/'+product.marca.id+'?text='+product.marca.name)" 
                        >{{ product.marca.name }}</span></p>
                    <h5>{{ product.title }}</h5>
                    <span>$ {{ getAmountTotal(product) }} </span> 
                    <del>$ {{ product.old_price }}</del>
                    <!-- <del v-if="product.discount_g || product.code_offer">$ {{ getAmountDiscount(product) }} </del> -->
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import CartResource from '../../resource/cart'
import WishListResource from '../../resource/wishlist'
import CompareResource from '../../resource/compare'
import myApi from '../../plugins/axios'
import store from '../../plugins/store'

export default {
    name:'CardKitA',
    props: ["product","is_not_detail","pc_build_attribute","type_card","is_pc_builder","ip_publica","pc_build_user_selected"],
    methods: {
        toUrlProduct(URL){
            window.location.href = URL;
        },
        getAmountTotal(product){
            if(product.discount_g && !product.code_offer){
                if(product.discount_g.discount_id){
                return Number(product.discount_g.new_amount.toFixed(2)).toFixed(2);
                // percentage
                }else{
                if(product.discount_g.type_discount == 1){//%
                    return Number((product.precio_general - (product.precio_general*product.discount_g.discount*0.01)).toFixed(2)).toFixed(2);
                }else{
                    return Number((product.precio_general - product.discount_g.discount).toFixed(2)).toFixed(2);
                }
                }
            }
            if(product.code_offer){
                if(product.code_offer.new_amount){
                return Number(product.code_offer.new_amount.toFixed(2)).toFixed(2);
                // percentage
                }else{
                if(product.code_offer.type_discount == 1){//%
                    return Number((product.precio_general - (product.precio_general*product.code_offer.discount*0.01)).toFixed(2)).toFixed(2);
                }else{
                    return Number((product.precio_general - product.code_offer.discount).toFixed(2)).toFixed(2);
                }
                }
            }
            return Number(product.precio_general).toFixed(2);
        },
        getUrlDiscount(product){
            if(product.code_offer){
                return "?CODE_OFFER="+product.code_offer.uniqd;
            }
            return "";
        },
        getAmountDiscount(product){
            return product.precio_general;
        },
        goDetailProduct(product){
            this.$router.push({
                name: 'DetailProduct',
                params: {
                slug: product.slug,
                }
            })
        },
        openDetailProduct(product){
            this.emitter.emit('product-detail-modal', product);
        },
        async pcBuildSelectedProduct(kit){
            // this.emitter.emit('pc-build-product-selec', product);

            try {
                const resp = await myApi.post(
                "ecommerce/pc_build/add-pc-build/",{
                    products: kit.products,
                    ip_publica: this.ip_publica,
                    pc_build_user_id:this.pc_build_user_selected.id,
                }
                );
                console.log(resp);
                if(resp.data.message == 403){
                this.$notify({
                    title: resp.data.message_text,
                    duration: 3500,
                    type: 'danger',
                });
                }else{
                this.$notify({
                    title: "Productos agregados al Pc Builder",
                    duration: 3500,
                    type: 'success',
                });
                this.emitter.emit("pc-build-add-kit", this.pc_build_user_selected);
                }
            } catch (error) {
            console.log(error);
            this.$notify({
                title: "Algo salio mal intentalo nuevamente",
                duration: 2500,
                type: 'error',
            });
            }
        },
        addWishList(productT){
            console.log(this.WISHLIST);
            let WISHLIST = this.WISHLIST;//$cookies.get("wishlist") ? this.$cookies.get("wishlist") : [];
            WishListResource.add(WISHLIST,productT,this.emitter, this.$notify,this.$cookies);
        },
        goToCompare(productT){
            // console.log(productT);
            CompareResource.add(productT,this.emitter, this.$notify,this.$cookies,this.$router)
        },
        async addCart(kit){
            let CARTS = this.CARTS;
            // CartResource.add(CARTS,PRODUCTY,this.emitter,this.$notify,this.$cookies);
            this.ERROR_TEXT = null;
            if(!store.getters.loggedIn){
                kit.products.forEach((prod) => {
                    let data = CartResource.register_guest(prod,this.quantity);
                    data.is_kit = 1;
                    CARTS.push(data);
                })
                this.emitter.emit("cart-shop", CARTS);
                localStorage.setItem('carts',JSON.stringify(CARTS));
                this.$notify({
                    title: "Productos agregados al carrito",
                    duration: 3500,
                    type: 'success',
                });
            }else{
                let NewProducts = [];
                kit.products.forEach((prod) => {
                    let data = CartResource.register_client(prod,this.quantity);
                    NewProducts.push(data);
                })
                try {
                    const resp = await myApi.post(
                        "ecommerce/carts-multiple/",{
                            products: NewProducts,
                        }
                    );
                    console.log(resp);
                    resp.data.carts.forEach((cart) => {
                        CARTS.push(CartResource.update(cart));
                    });
                    this.emitter.emit("cart-shop", CARTS);
                    this.$notify({
                        title: "Productos agregados al carrito",
                        duration: 3500,
                        type: 'success',
                    });
                } catch (error) {
                    console.log(error);
                    this.$notify({
                    title: "Algo salio mal intentalo nuevamente",
                    duration: 2500,
                    type: 'error',
                    });
                }
            }
        },
        getDiscountPrice(product){
            if(typeof product.discount_g.discount === 'string' || product.discount_g.discount instanceof String){
                return (Number.isInteger(parseFloat(product.discount_g.discount)) ? parseInt(parseFloat(product.discount_g.discount)) : (product.discount_g.discount).toFixed(2))+ "%";//parseInt
            }else{
                if(product.discount_g.discount){
                    return (Number.isInteger(product.discount_g.percentage) ? parseInt(product.discount_g.percentage) : (product.discount_g.percentage).toFixed(2))+"%";//Math.ceil
                }
            }
            return "%"
        },
        getOfferPrice(product){
            if(product.code_offer.type_discount == 1){
                return parseInt(product.code_offer.discount)+"%";
            }
            if(product.code_offer.type_discount == 2){
                return "$";
            }
            if(!product.code_offer.type_discount){
                return this.getDiscountPrice(product);
            }
            return "%"
        },
        getTagNew(product){
            if(product.tags_multiples.length > 0){
                return product.tags_multiples.find(item => item == '1') || product.tags_multiples.find(item => item == '3');
            }
            return false;
        },
        getNameTagNew(product){
            if(product.tags_multiples.length > 0){
                let tag_m = product.tags_multiples.find(item => item == '1');
                let tag_m_2 = product.tags_multiples.find(item => item == '3');
                if(tag_m || tag_m_2){
                    if(tag_m_2){
                        return "Reingreso";
                    }
                    if(tag_m){
                        return "Nuevo";
                    }
                }
            }
            return false;
        },
    },
    mounted(){
        // PARA EL CARRITO
        this.emitter.on('cart-shop',data => {
            this.CARTS = data;
        });
        this.emitter.emit("cart-list", {});

        //PARA LA LISTA DE DESEO
        this.emitter.on('wish-list-product',data => {
            this.WISHLIST = data;
        });
        this.emitter.emit("wish-list-list", {});
    }
}
</script>
<style>
    .bg-primary{
        border-radius: initial !important;
    }
    /* .v-progressive-image{
        height: 254px !important;
    } */
</style>