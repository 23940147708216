const MUNICIPIOS = [
    { "state": "Ahuachapán", "city": "Ahuachapán", "zip": "2101" , "title": "Ciudad Cabecera" , "district": "Ahuachapán" },
    { "state": "Ahuachapán", "city": "Apaneca", "zip": "2102" , "title": "Villa" , "district": "Ahuachapán" },
    { "state": "Ahuachapán", "city": "Atiquizaya", "zip": "2103" , "title": "Ciudad" , "district": "Atiquizaya" },
    { "state": "Ahuachapán", "city": "Concepción de Ataco", "zip": "2106" , "title": "Ciudad" , "district": "Ahuachapán" },
    { "state": "Ahuachapán", "city": "El Refugio" , "zip": "2107", "title": "Pueblo" , "district": "Atiquizaya" },
    { "state": "Ahuachapán", "city": "Guaymango" , "zip": "2108", "title": "Pueblo" , "district": "Ahuachapán" },
    { "state": "Ahuachapán", "city": "Jujutla" , "zip": "2109", "title": "Villa" , "district": "Ahuachapán" },
    { "state": "Ahuachapán", "city": "San Francisco Menéndez" , "zip": "2113", "title": "Pueblo" , "district": "Ahuachapán" },
    { "state": "Ahuachapán", "city": "San Lorenzo" , "zip": "2115", "title": "Pueblo" , "district": "Atiquizaya" },
    { "state": "Ahuachapán", "city": "San Pedro Puxtla" , "zip": "2116", "title": "Villa" , "district": "Ahuachapán" },
    { "state": "Ahuachapán", "city": "Tacuba" , "zip": "2117", "title": "Ciudad" , "district": "Ahuachapán" },
    { "state": "Ahuachapán", "city": "Turín" , "zip": "2118", "title": "Pueblo" , "district": "Atiquizaya" },
    { "state": "Cabañas", "city": "Sensuntepeque" , "zip": "1201", "title": "Ciudad Cabecera" , "district": "Sensuntepeque" },
    { "state": "Cabañas", "city": "Cinquera" , "zip": "1202", "title": "Pueblo" , "district": "Ilobasco" },
    { "state": "Cabañas", "city": "Dolores" , "zip": "1209", "title": "Ciudad" , "district": "Sensuntepeque" },
    { "state": "Cabañas", "city": "Guacotecti" , "zip": "1203", "title": "Villa" , "district": "Sensuntepeque" },
    { "state": "Cabañas", "city": "Ilobasco" , "zip": "1204", "title": "Ciudad" , "district": "Ilobasco" },
    { "state": "Cabañas", "city": "Jutiapa" , "zip": "1206", "title": "Villa" , "district": "Ilobasco" },
    { "state": "Cabañas", "city": "San Isidro" , "zip": "1207", "title": "Ciudad" , "district": "Sensuntepeque" },
    { "state": "Cabañas", "city": "Tejutepeque" , "zip": "1208", "title": "Ciudad" , "district": "Ilobasco" },
    { "state": "Cabañas", "city": "Victoria" , "zip": "1210", "title": "Ciudad" , "district": "Sensuntepeque" },
    { "state": "Chalatenango", "city": "Chalatenango" , "zip": "1301", "title": "Ciudad Cabecera" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "Agua Caliente" , "zip": "1302", "title": "Villa" , "district": "Tejutla" },
    { "state": "Chalatenango", "city": "Arcatao" , "zip": "1303", "title": "Ciudad" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "Azacualpa" , "zip": "1304", "title": "Pueblo" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "Cancasque" , "zip": "1305", "title": "Pueblo" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "Citalá" , "zip": "1306", "title": "Villa" , "district": "Tejutla" },
    { "state": "Chalatenango", "city": "Comalapa" , "zip": "1307", "title": "Pueblo" , "district": "Dulce Nombre de María" },
    { "state": "Chalatenango", "city": "Concepción Quezaltepeque" , "zip": "1308", "title": "Villa" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "Dulce Nombre de María" , "zip": "1309", "title": "Ciudad" , "district": "Dulce Nombre de María" },
    { "state": "Chalatenango", "city": "El Carrizal" , "zip": "1311", "title": "Pueblo" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "El Paraíso" , "zip": "1312", "title": "Pueblo" , "district": "Dulce Nombre de María" },
    { "state": "Chalatenango", "city": "La Laguna" , "zip": "1313", "title": "Pueblo" , "district": "Dulce Nombre de María" },
    { "state": "Chalatenango", "city": "La Palma" , "zip": "1314", "title": "Ciudad" , "district": "Tejutla" },
    { "state": "Chalatenango", "city": "La Reina" , "zip": "1315", "title": "Villa" , "district": "Tejutla" },
    { "state": "Chalatenango", "city": "Las Flores" , "zip": "1316", "title": "Pueblo" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "Las Vueltas" , "zip": "1317", "title": "Pueblo" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "Nombre de Jesús" , "zip": "1318", "title": "Pueblo" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "Nueva Concepción" , "zip": "1319", "title": "Ciudad" , "district": "Tejutla" },
    { "state": "Chalatenango", "city": "Nueva Trinidad" , "zip": "1320", "title": "Pueblo" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "Ojos de Agua" , "zip": "1321", "title": "Pueblo" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "Potonico" , "zip": "1322", "title": "Pueblo" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "San Antonio de la Cruz" , "zip": "1324", "title": "Pueblo" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "San Antonio Los Ranchos" , "zip": "1325", "title": "Pueblo" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "San Fernando" , "zip": "1326", "title": "Villa" , "district": "Dulce Nombre de María" },
    { "state": "Chalatenango", "city": "San Francisco Lempa" , "zip": "1327", "title": "Villa" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "San Francisco Morazán" , "zip": "1328", "title": "Pueblo" , "district": "Dulce Nombre de María" },
    { "state": "Chalatenango", "city": "San Ignacio" , "zip": "1329", "title": "Villa" , "district": "Tejutla" },
    { "state": "Chalatenango", "city": "San Isidro Labrador" , "zip": "1330", "title": "Villa" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "San Luis del Carmen" , "zip": "1331", "title": "Pueblo" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "San Miguel de Mercedes" , "zip": "1332", "title": "Villa" , "district": "Chalatenango" },
    { "state": "Chalatenango", "city": "San Rafael" , "zip": "1333", "title": "Pueblo" , "district": "Dulce Nombre de María" },
    { "state": "Chalatenango", "city": "Santa Rita" , "zip": "1334", "title": "Pueblo" , "district": "Dulce Nombre de María" },
    { "state": "Chalatenango", "city": "Tejutla" , "zip": "1335", "title": "Ciudad" , "district": "Tejutla" },
    { "state": "Cuscatlán", "city": "Cojutepeque" , "zip": "1401", "title": "Ciudad Cabecera" , "district": "Cojutepeque" },
    { "state": "Cuscatlán", "city": "Candelaria" , "zip": "1302", "title": "Villa" , "district": "Cojutepeque" },
    { "state": "Cuscatlán", "city": "El Carmen" , "zip": "1403", "title": "Pueblo" , "district": "Cojutepeque" },
    { "state": "Cuscatlán", "city": "El Rosario" , "zip": "1404", "title": "Pueblo" , "district": "Cojutepeque" },
    { "state": "Cuscatlán", "city": "Monte San Juan" , "zip": "1405", "title": "Pueblo" , "district": "Cojutepeque" },
    { "state": "Cuscatlán", "city": "Oratorio de Concepción" , "zip": "1406", "title": "Pueblo" , "district": "Suchitoto" },
    { "state": "Cuscatlán", "city": "San Bartolomé Perulapía" , "zip": "1407", "title": "Villa" , "district": "Cojutepeque" },
    { "state": "Cuscatlán", "city": "San Cristóbal" , "zip": "1408", "title": "Villa" , "district": "Cojutepeque" },
    { "state": "Cuscatlán", "city": "San José Guayabal" , "zip": "1409", "title": "Villa" , "district": "Suchitoto" },
    { "state": "Cuscatlán", "city": "San Pedro Perulapán" , "zip": "1410", "title": "Ciudad" , "district": "Cojutepeque" },
    { "state": "Cuscatlán", "city": "San Rafael Cedros" , "zip": "1411", "title": "Ciudad" , "district": "Cojutepeque" },
    { "state": "Cuscatlán", "city": "San Ramón" , "zip": "1412", "title": "Pueblo" , "district": "Cojutepeque" },
    { "state": "Cuscatlán", "city": "Santa Cruz Analquito" , "zip": "1413", "title": "Pueblo" , "district": "Cojutepeque" },
    { "state": "Cuscatlán", "city": "Santa Cruz Michapa" , "zip": "1414", "title": "Pueblo" , "district": "Cojutepeque" },
    { "state": "Cuscatlán", "city": "Suchitoto" , "zip": "1415", "title": "Ciudad" , "district": "Suchitoto" },
    { "state": "Cuscatlán", "city": "Tenancingo" , "zip": "1416", "title": "Villa" , "district": "Cojutepeque" },
    { "state": "La Libertad", "city": "Santa Tecla" , "zip": "1501", "title": "Ciudad Cabecera" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "Antiguo Cuscatlán" , "zip": "1502", "title": "Ciudad" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "Chiltiupán" , "zip": "1507", "title": "Pueblo" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "Ciudad Arce" , "zip": "1504", "title": "Ciudad" , "district": "San Juan Opico" },
    { "state": "La Libertad", "city": "Colón" , "zip": "1512", "title": "Villa" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "Comasagua" , "zip": "1506", "title": "Villa" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "Huizúcar" , "zip": "1508", "title": "Villa" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "Jayaque" , "zip": "1509", "title": "Ciudad" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "Jicalapa" , "zip": "1510", "title": "Pueblo" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "La Libertad" , "zip": "1511", "title": "Ciudad" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "Nuevo Cuscatlán" , "zip": "1513", "title": "Pueblo" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "Quezaltepeque" , "zip": "1515", "title": "Ciudad" , "district": "Quezaltepeque" },
    { "state": "La Libertad", "city": "San Juan Opico" , "zip": "1514", "title": "Ciudad" , "district": "San Juan Opico" },
    { "state": "La Libertad", "city": "Sacacoyo" , "zip": "1516", "title": "Pueblo" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "San José Villanueva" , "zip": "1517", "title": "Pueblo" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "San Matías" , "zip": "1518", "title": "Villa" , "district": "San Juan Opico" },
    { "state": "La Libertad", "city": "San Pablo Tacachico" , "zip": "1519", "title": "Villa" , "district": "Quezaltepeque" },
    { "state": "La Libertad", "city": "Talnique" , "zip": "1521", "title": "Pueblo" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "Tamanique" , "zip": "1522", "title": "Pueblo" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "Teotepeque" , "zip": "1523", "title": "Villa" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "Tepecoyo" , "zip": "1524", "title": "Villa" , "district": "Santa Tecla" },
    { "state": "La Libertad", "city": "Zaragoza" , "zip": "1525", "title": "Ciudad" , "district": "Santa Tecla" },
    { "state": "La Paz", "city": "Zacatecoluca" , "zip": "1601", "title": "Ciudad Cabecera" , "district": "Zacatecoluca" },
    { "state": "La Paz", "city": "Cuyultitán" , "zip": "1603", "title": "Villa" , "district": "Olocuilta" },
    { "state": "La Paz", "city": "El Rosario" , "zip": "1604", "title": "Ciudad" , "district": "San Pedro Masahuat" },
    { "state": "La Paz", "city": "Jerusalén" , "zip": "1605", "title": "Pueblo" , "district": "San Pedro Nonualco" },
    { "state": "La Paz", "city": "Mercedes La Ceiba" , "zip": "1607", "title": "Pueblo" , "district": "San Pedro Nonualco" },
    { "state": "La Paz", "city": "Olocuilta" , "zip": "1608", "title": "Ciudad" , "district": "Olocuilta" },
    { "state": "La Paz", "city": "Paraíso de Osorio" , "zip": "1609", "title": "Pueblo" , "district": "San Pedro Nonualco" },
    { "state": "La Paz", "city": "San Antonio Masahuat" , "zip": "1610", "title": "Pueblo" , "district": "San Pedro Masahuat" },
    { "state": "La Paz", "city": "San Emigdio" , "zip": "1611", "title": "Pueblo" , "district": "San Pedro Nonualco" },
    { "state": "La Paz", "city": "San Francisco Chinameca" , "zip": "1612", "title": "Pueblo" , "district": "Olocuilta" },
    { "state": "La Paz", "city": "San Pedro Masahuat" , "zip": "1618", "title": "Ciudad" , "district": "San Pedro Masahuat" },
    { "state": "La Paz", "city": "San Juan Nonualco" , "zip": "1613", "title": "Ciudad" , "district": "Zacatecoluca" },
    { "state": "La Paz", "city": "San Juan Talpa" , "zip": "1614", "title": "Villa" , "district": "Olocuilta" },
    { "state": "La Paz", "city": "San Juan Tepezontes" , "zip": "1615", "title": "Villa" , "district": "San Pedro Masahuat" },
    { "state": "La Paz", "city": "San Luis La Herradura" , "zip": "1606", "title": "Pueblo" , "district": "Zacatecoluca" },
    { "state": "La Paz", "city": "San Luis Talpa" , "zip": "1616", "title": "Villa" , "district": "Olocuilta" },
    { "state": "La Paz", "city": "San Miguel Tepezontes" , "zip": "1617", "title": "Villa" , "district": "San Pedro Masahuat" },
    { "state": "La Paz", "city": "San Pedro Nonualco" , "zip": "1619", "title": "Ciudad" , "district": "San Pedro Nonualco" },
    { "state": "La Paz", "city": "San Rafael Obrajuelo" , "zip": "1620", "title": "Ciudad" , "district": "Zacatecoluca" },
    { "state": "La Paz", "city": "Santa María Ostuma" , "zip": "1621", "title": "Villa" , "district": "San Pedro Nonualco" },
    { "state": "La Paz", "city": "Santiago Nonualco" , "zip": "1622", "title": "Ciudad" , "district": "Zacatecoluca" },
    { "state": "La Paz", "city": "Tapalhuaca" , "zip": "1623", "title": "Pueblo" , "district": "Olocuilta" },
    { "state": "La Unión", "city": "La Unión" , "zip": "3101", "title": "Ciudad Cabecera" , "district": "La Unión" },
    { "state": "La Unión", "city": "Anamorós" , "zip": "3104", "title": "Ciudad" , "district": "Santa Rosa de Lima" },
    { "state": "La Unión", "city": "Bolívar" , "zip": "3105", "title": "Pueblo" , "district": "La Unión" },
    { "state": "La Unión", "city": "Concepción de Oriente" , "zip": "3106", "title": "Villa" , "district": "Santa Rosa de Lima" },
    { "state": "La Unión", "city": "Conchagua" , "zip": "3107", "title": "Villa" , "district": "La Unión" },
    { "state": "La Unión", "city": "El Carmen" , "zip": "3108", "title": "Villa" , "district": "La Unión" },
    { "state": "La Unión", "city": "El Sauce" , "zip": "3109", "title": "Villa" , "district": "Santa Rosa de Lima" },
    { "state": "La Unión", "city": "Intipucá" , "zip": "3111", "title": "Villa" , "district": "La Unión" },
    { "state": "La Unión", "city": "Lislique" , "zip": "3112", "title": "Villa" , "district": "Santa Rosa de Lima" },
    { "state": "La Unión", "city": "Meanguera del Golfo" , "zip": "3113", "title": "Pueblo" , "district": "La Unión" },
    { "state": "La Unión", "city": "Nueva Esparta" , "zip": "3114", "title": "Ciudad" , "district": "Santa Rosa de Lima" },
    { "state": "La Unión", "city": "Pasaquina" , "zip": "3116", "title": "Ciudad" , "district": "Santa Rosa de Lima" },
    { "state": "La Unión", "city": "Polorós" , "zip": "3117", "title": "Villa" , "district": "Santa Rosa de Lima" },
    { "state": "La Unión", "city": "San Alejo" , "zip": "3119", "title": "Ciudad" , "district": "La Unión" },
    { "state": "La Unión", "city": "San José" , "zip": "3120", "title": "Villa" , "district": "La Unión" },
    { "state": "La Unión", "city": "Santa Rosa de Lima" , "zip": "3121", "title": "Ciudad" , "district": "Santa Rosa de Lima" },
    { "state": "La Unión", "city": "Yayantique" , "zip": "3122", "title": "Pueblo" , "district": "La Unión" },
    { "state": "La Unión", "city": "Yucuaiquín" , "zip": "3123", "title": "Villa" , "district": "La Unión" },
    { "state": "Morazán", "city": "San Francisco Gotera" , "zip": "3201", "title": "Ciudad Cabecera" , "district": "San Francisco" },
    { "state": "Morazán", "city": "Arambala" , "zip": "3202", "title": "Pueblo" , "district": "Jocoaitique" },
    { "state": "Morazán", "city": "Cacaopera" , "zip": "3216", "title": "Ciudad" , "district": "Osicala" },
    { "state": "Morazán", "city": "Chilanga" , "zip": "3203", "title": "Villa" , "district": "San Francisco" },
    { "state": "Morazán", "city": "Corinto" , "zip": "3204", "title": "Ciudad" , "district": "Osicala" },
    { "state": "Morazán", "city": "Delicias de Concepción" , "zip": "3206", "title": "Pueblo" , "district": "Osicala" },
    { "state": "Morazán", "city": "El Divisadero" , "zip": "3207", "title": "Villa" , "district": "San Francisco" },
    { "state": "Morazán", "city": "El Rosario" , "zip": "3208", "title": "Villa" , "district": "Jocoaitique" },
    { "state": "Morazán", "city": "Gualococti" , "zip": "3209", "title": "Pueblo" , "district": "Osicala" },
    { "state": "Morazán", "city": "Guatajiagua" , "zip": "3210", "title": "Ciudad" , "district": "San Francisco" },
    { "state": "Morazán", "city": "Joateca" , "zip": "3211", "title": "Pueblo" , "district": "Jocoaitique" },
    { "state": "Morazán", "city": "Jocoaitique" , "zip": "3212", "title": "Ciudad" , "district": "Jocoaitique" },
    { "state": "Morazán", "city": "Jocoro" , "zip": "3213", "title": "Ciudad" , "district": "San Francisco" },
    { "state": "Morazán", "city": "Lolotiquillo" , "zip": "3214", "title": "Pueblo" , "district": "San Francisco" },
    { "state": "Morazán", "city": "Meanguera" , "zip": "3215", "title": "Ciudad" , "district": "Jocoaitique" },
    { "state": "Morazán", "city": "Osicala" , "zip": "3216", "title": "Ciudad" , "district": "Osicala" },
    { "state": "Morazán", "city": "Perquín" , "zip": "3217", "title": "Pueblo" , "district": "Jocoaitique" },
    { "state": "Morazán", "city": "San Carlos" , "zip": "3218", "title": "Ciudad" , "district": "San Francisco" },
    { "state": "Morazán", "city": "San Fernando" , "zip": "3219", "title": "Pueblo" , "district": "Jocoaitique" },
    { "state": "Morazán", "city": "San Isidro" , "zip": "3220", "title": "Villa" , "district": "Osicala" },
    { "state": "Morazán", "city": "San Simón" , "zip": "3221", "title": "Villa" , "district": "Osicala" },
    { "state": "Morazán", "city": "Sensembra" , "zip": "3222", "title": "Pueblo" , "district": "San Francisco" },
    { "state": "Morazán", "city": "Sociedad" , "zip": "3223", "title": "Ciudad" , "district": "San Francisco" },
    { "state": "Morazán", "city": "Torola" , "zip": "3224", "title": "Pueblo" , "district": "Jocoaitique" },
    { "state": "Morazán", "city": "Yamabal" , "zip": "3225", "title": "Pueblo" , "district": "San Francisco" },
    { "state": "Morazán", "city": "Yoloaiquín" , "zip": "3226", "title": "Pueblo" , "district": "Osicala" },
    { "state": "San Miguel", "city": "San Miguel" , "zip": "3301", "title": "Ciudad Cabecera" , "district": "San Miguel" },
    { "state": "San Miguel", "city": "Carolina" , "zip": "3302", "title": "Villa" , "district": "Sesori" },
    { "state": "San Miguel", "city": "Chapeltique" , "zip": "3305", "title": "Ciudad" , "district": "San Miguel" },
    { "state": "San Miguel", "city": "Chinameca" , "zip": "3306", "title": "Ciudad" , "district": "Chinameca" },
    { "state": "San Miguel", "city": "Chirilagua" , "zip": "3307", "title": "Ciudad" , "district": "San Miguel" },
    { "state": "San Miguel", "city": "Ciudad Barrios" , "zip": "3303", "title": "Ciudad" , "district": "San Miguel" },
    { "state": "San Miguel", "city": "Comacarán" , "zip": "3304", "title": "Villa" , "district": "San Miguel" },
    { "state": "San Miguel", "city": "El Tránsito" , "zip": "3309", "title": "Ciudad" , "district": "Chinameca" },
    { "state": "San Miguel", "city": "Lolotique" , "zip": "3311", "title": "Ciudad" , "district": "Chinameca" },
    { "state": "San Miguel", "city": "Moncagua" , "zip": "3312", "title": "Ciudad" , "district": "San Miguel" },
    { "state": "San Miguel", "city": "Nueva Guadalupe" , "zip": "3313", "title": "Ciudad" , "district": "Chinameca" },
    { "state": "San Miguel", "city": "Nuevo Edén de San Juan" , "zip": "3314", "title": "Pueblo" , "district": "Sesori" },
    { "state": "San Miguel", "city": "Quelepa" , "zip": "3315", "title": "Villa" , "district": "San Miguel" },
    { "state": "San Miguel", "city": "San Antonio del Mosco" , "zip": "3316", "title": "Pueblo" , "district": "Sesori" },
    { "state": "San Miguel", "city": "San Gerardo" , "zip": "3318", "title": "Villa" , "district": "Sesori" },
    { "state": "San Miguel", "city": "San Jorge" , "zip": "3319", "title": "Ciudad" , "district": "Chinameca" },
    { "state": "San Miguel", "city": "San Luis de la Reina" , "zip": "3320", "title": "Villa" , "district": "Sesori" },
    { "state": "San Miguel", "city": "San Rafael Oriente" , "zip": "3322", "title": "Ciudad" , "district": "Chinameca" },
    { "state": "San Miguel", "city": "Sesori" , "zip": "3323", "title": "Ciudad" , "district": "Sesori" },
    { "state": "San Miguel", "city": "Uluazapa" , "zip": "3324", "title": "Villa" , "district": "San Miguel" },
    { "state": "San Salvador", "city": "San Salvador" , "zip": "1101", "title": "Ciudad Cabecera Capital" , "district": "San Salvador" },
    { "state": "San Salvador", "city": "Aguilares" , "zip": "1122", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "state": "San Salvador", "city": "Apopa" , "zip": "1123", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "state": "San Salvador", "city": "Ayutuxtepeque" , "zip": "1121", "title": "Ciudad" , "district": "San Salvador" },
    { "state": "San Salvador", "city": "Cuscatancingo" , "zip": "1119", "title": "Ciudad" , "district": "San Salvador" },
    { "state": "San Salvador", "city": "Delgado" , "zip": "1118", "title": "Ciudad" , "district": "San Salvador" },
    { "state": "San Salvador", "city": "El Paisnal" , "zip": "1124", "title": "Villa" , "district": "Tonacatepeque" },
    { "state": "San Salvador", "city": "Guazapa" , "zip": "1125", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "state": "San Salvador", "city": "Ilopango" , "zip": "1117", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "state": "San Salvador", "city": "Mejicanos" , "zip": "1120", "title": "Ciudad" , "district": "San Salvador" },
    { "state": "San Salvador", "city": "Nejapa" , "zip": "1126", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "state": "San Salvador", "city": "Panchimalco" , "zip": "1127", "title": "Villa" , "district": "Santo Tomás" },
    { "state": "San Salvador", "city": "Rosario de Mora" , "zip": "1128", "title": "Pueblo" , "district": "Santo Tomás" },
    { "state": "San Salvador", "city": "San Marcos" , "zip": "1115", "title": "Ciudad" , "district": "Santo Tomás" },
    { "state": "San Salvador", "city": "San Martín" , "zip": "1129", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "state": "San Salvador", "city": "Santiago Texacuangos" , "zip": "1130", "title": "Villa" , "district": "Santo Tomás" },
    { "state": "San Salvador", "city": "Santo Tomás" , "zip": "1131", "title": "Ciudad" , "district": "Santo Tomás" },
    { "state": "San Salvador", "city": "Soyapango" , "zip": "1116", "title": "Ciudad" , "district": "San Salvador" },
    { "state": "San Salvador", "city": "Tonacatepeque" , "zip": "1132", "title": "Ciudad" , "district": "Tonacatepeque" },
    { "state": "San Vicente", "city": "San Vicente" , "zip": "1701", "title": "Ciudad Cabecera" , "district": "San Vicente" },
    { "state": "San Vicente", "city": "Apastepeque" , "zip": "1702", "title": "Ciudad" , "district": "San Vicente" },
    { "state": "San Vicente", "city": "Guadalupe" , "zip": "1703", "title": "Ciudad" , "district": "San Vicente" },
    { "state": "San Vicente", "city": "San Cayetano Istepeque" , "zip": "1704", "title": "Villa" , "district": "San Vicente" },
    { "state": "San Vicente", "city": "San Esteban Catarina" , "zip": "1705", "title": "Villa" , "district": "San Sebastián" },
    { "state": "San Vicente", "city": "San Ildefonso" , "zip": "1706", "title": "Villa" , "district": "San Sebastián" },
    { "state": "San Vicente", "city": "San Lorenzo" , "zip": "1707", "title": "Villa" , "district": "San Sebastián" },
    { "state": "San Vicente", "city": "San Sebastián" , "zip": "1708", "title": "Ciudad" , "district": "San Sebastián" },
    { "state": "San Vicente", "city": "Santa Clara" , "zip": "1709", "title": "Villa" , "district": "San Sebastián" },
    { "state": "San Vicente", "city": "Santo Domingo" , "zip": "1710", "title": "Ciudad" , "district": "San Sebastián" },
    { "state": "San Vicente", "city": "Tecoluca" , "zip": "1711", "title": "Ciudad" , "district": "San Vicente" },
    { "state": "San Vicente", "city": "Tepetitán" , "zip": "1712", "title": "Villa" , "district": "San Vicente" },
    { "state": "San Vicente", "city": "Verapaz" , "zip": "1713", "title": "Ciudad" , "district": "San Vicente" },
    { "state": "Santa Ana", "city": "Santa Ana" , "zip": "2201", "title": "Ciudad Cabecera" , "district": "Santa Ana" },
    { "state": "Santa Ana", "city": "Candelaria de la Frontera" , "zip": "2203", "title": "Villa" , "district": "Chalchuapa" },
    { "state": "Santa Ana", "city": "Chalchuapa" , "zip": "2205", "title": "Ciudad" , "district": "Chalchuapa" },
    { "state": "Santa Ana", "city": "Coatepeque" , "zip": "2204", "title": "Ciudad" , "district": "Santa Ana" },
    { "state": "Santa Ana", "city": "El Congo" , "zip": "2207", "title": "Villa" , "district": "Santa Ana" },
    { "state": "Santa Ana", "city": "El Porvenir" , "zip": "2208", "title": "Pueblo" , "district": "Chalchuapa" },
    { "state": "Santa Ana", "city": "Masahuat" , "zip": "2210", "title": "Pueblo" , "district": "Metapán" },
    { "state": "Santa Ana", "city": "Metapán" , "zip": "2211", "title": "Ciudad" , "district": "Metapán" },
    { "state": "Santa Ana", "city": "San Antonio Pajonal" , "zip": "2212", "title": "Pueblo" , "district": "Chalchuapa" },
    { "state": "Santa Ana", "city": "San Sebastián Salitrillo" , "zip": "2215", "title": "Pueblo" , "district": "Chalchuapa" },
    { "state": "Santa Ana", "city": "Santa Rosa Guachipilín" , "zip": "2216", "title": "Pueblo" , "district": "Metapán" },
    { "state": "Santa Ana", "city": "Santiago de la Frontera" , "zip": "2217", "title": "Pueblo" , "district": "Metapán" },
    { "state": "Santa Ana", "city": "Texistepeque" , "zip": "2218", "title": "Ciudad" , "district": "Santa Ana" },
    { "state": "Sonsonate", "city": "Sonsonate" , "zip": "2301", "title": "Ciudad Cabecera" , "district": "Sonsonate" },
    { "state": "Sonsonate", "city": "Acajutla" , "zip": "2302", "title": "Ciudad" , "district": "Sonsonate" },
    { "state": "Sonsonate", "city": "Armenia" , "zip": "2303", "title": "Ciudad" , "district": "Izalco" },
    { "state": "Sonsonate", "city": "Caluco" , "zip": "2304", "title": "Pueblo" , "district": "Izalco" },
    { "state": "Sonsonate", "city": "Cuisnahuat" , "zip": "2305", "title": "Pueblo" , "district": "Izalco" },
    { "state": "Sonsonate", "city": "Izalco" , "zip": "2306", "title": "Ciudad" , "district": "Izalco" },
    { "state": "Sonsonate", "city": "Juayúa" , "zip": "2307", "title": "Ciudad" , "district": "Juayúa" },
    { "state": "Sonsonate", "city": "Nahuizalco" , "zip": "2311", "title": "Ciudad" , "district": "Sonsonate" },
    { "state": "Sonsonate", "city": "Nahulingo" , "zip": "2312", "title": "Pueblo" , "district": "Sonsonate" },
    { "state": "Sonsonate", "city": "Salcoatitán" , "zip": "2313", "title": "Pueblo" , "district": "Juayúa" },
    { "state": "Sonsonate", "city": "San Antonio del Monte" , "zip": "2314", "title": "Villa" , "district": "Sonsonate" },
    { "state": "Sonsonate", "city": "San Julián" , "zip": "2316", "title": "Ciudad" , "district": "Izalco" },
    { "state": "Sonsonate", "city": "Santa Catarina Masahuat" , "zip": "2317", "title": "Pueblo" , "district": "Juayúa" },
    { "state": "Sonsonate", "city": "Santa Isabel Ishuatán" , "zip": "2318", "title": "Villa" , "district": "Izalco" },
    { "state": "Sonsonate", "city": "Santo Domingo de Guzmán" , "zip": "2319", "title": "Pueblo" , "district": "Sonsonate" },
    { "state": "Sonsonate", "city": "Sonzacate" , "zip": "2320", "title": "Pueblo" , "district": "Sonsonate" },
    { "state": "Usulután", "city": "Usulután" , "zip": "3401", "title": "Ciudad Cabecera" , "district": "Usulután" },
    { "state": "Usulután", "city": "Alegría" , "zip": "3404", "title": "Ciudad" , "district": "Santiago de María" },
    { "state": "Usulután", "city": "Berlín" , "zip": "3403", "title": "Ciudad" , "district": "Berlín" },
    { "state": "Usulután", "city": "California" , "zip": "3404", "title": "Pueblo" , "district": "Santiago de María" },
    { "state": "Usulután", "city": "Concepción Batres" , "zip": "3405", "title": "Villa" , "district": "Usulután" },
    { "state": "Usulután", "city": "El Triunfo" , "zip": "3406", "title": "Villa" , "district": "Estanzuelas" },
    { "state": "Usulután", "city": "Ereguayquín" , "zip": "3407", "title": "Pueblo" , "district": "Usulután" },
    { "state": "Usulután", "city": "Estanzuelas" , "zip": "3408", "title": "Ciudad" , "district": "Estanzuelas" },
    { "state": "Usulután", "city": "Jiquilisco" , "zip": "3409", "title": "Ciudad" , "district": "Usulután" },
    { "state": "Usulután", "city": "Jucuapa" , "zip": "3410", "title": "Villa" , "district": "Estanzuelas" },
    { "state": "Usulután", "city": "Jucuarán" , "zip": "3411", "title": "Villa" , "district": "Usulután" },
    { "state": "Usulután", "city": "Mercedes Umaña" , "zip": "3412", "title": "Villa" , "district": "Berlín" },
    { "state": "Usulután", "city": "Nueva Granada" , "zip": "3413", "title": "Villa" , "district": "Estanzuelas" },
    { "state": "Usulután", "city": "Ozatlán" , "zip": "3415", "title": "Villa" , "district": "Usulután" },
    { "state": "Usulután", "city": "Puerto El Triunfo" , "zip": "3417", "title": "Pueblo" , "district": "Usulután" },
    { "state": "Usulután", "city": "San Agustín" , "zip": "3418", "title": "Villa" , "district": "Berlín" },
    { "state": "Usulután", "city": "San Buenaventura" , "zip": "3419", "title": "Pueblo" , "district": "Estanzuelas" },
    { "state": "Usulután", "city": "San Dionisio" , "zip": "3420", "title": "Pueblo" , "district": "Usulután" },
    { "state": "Usulután", "city": "San Francisco Javier" , "zip": "3421", "title": "Pueblo" , "district": "Berlín" },
    { "state": "Usulután", "city": "Santa Elena" , "zip": "3422", "title": "Ciudad" , "district": "Usulután" },
    { "state": "Usulután", "city": "Santa María" , "zip": "3423", "title": "Pueblo" , "district": "Usulután" },
    { "state": "Usulután", "city": "Santiago de María" , "zip": "3424", "title": "Ciudad" , "district": "Santiago de María" },
    { "state": "Usulután", "city": "Tecapán" , "zip": "3426" , "title": "Villa" , "district": "Santiago de María" }
];

const DEPARTAMENTOS = [
    {
      "id": 1,
      "name": "Ahuachapán"
    },
    {
      "id": 2,
      "name": "Cabañas"
    },
    {
      "id": 3,
      "name": "Chalatenango"
    },
    {
      "id": 4,
      "name": "Cuscatlán"
    },
    {
      "id": 5,
      "name": "Morazán"
    },
    {
      "id": 6,
      "name": "La Libertad"
    },
    {
      "id": 7,
      "name": "La Paz"
    },
    {
      "id": 8,
      "name": "La Unión"
    },
    {
      "id": 9,
      "name": "San Miguel"
    },
    {
      "id": 10,
      "name": "San Salvador"
    },
    {
      "id": 11,
      "name": "San Vicente"
    },
    {
      "id": 12,
      "name": "Santa Ana"
    },
    {
      "id": 13,
      "name": "Sonsonate"
    },
    {
      "id": 14,
      "name": "Usulután"
    }
]

export default {
    DEPARTAMENTOS,
    MUNICIPIOS
}