<template>
    <div>
        <p>dadasdas</p>
        <button class="btn btn-primary" @click="API_BANC_PASARELA()"> PROCESS PAGO</button>
        <br>
        <template v-if="embed">
            <iframe id="threedsIframe" ref="threedsIframe" :srcdoc="embed" 
                style="width: 80%;height: 350px;"
            >
            </iframe>
        </template>
    </div>
</template>
<script>
import myApi from '../../plugins/axios'

export default {
    name: 'CardHolder',
    data() {
        return {
            embed:null,
        }
    },
    methods: {
        async API_BANC_PASARELA(){
            try {
                const resp = await myApi.post('banc-api-request',{});
                console.log(resp);
                this.embed = resp.data.response.RedirectData;
                console.log(this.embed);
            } catch (error) {
                console.log(error);
            }
        }
    },
}
</script>