<script setup>
import {loadScript} from '@paypal/paypal-js';
import {onMounted, defineProps, defineEmits, toRefs} from 'vue';

// let referenceT = ref(null);

const props = defineProps({
    // Some kind of reference if you like
    reference: Object
});
const emit = defineEmits({})
// console.log(props.reference);
let {reference} = toRefs(props);

const onApproveR = (newValue) => {
    emit('onApproveR', newValue);
}

const onErrorR = (newValue) => {
    emit('onErrorR', newValue);
}
onMounted(async() => {
    try {
        const paypal = await loadScript({
            'client-id': 'AQ1v3OxX94Lih1n0I9vubjoq0IlYeXTT1QEwJeepphqfAZMAo3TeKZlsQGn6lyfGer-qmz1Mi8uncyYD'
        });

        await paypal.Buttons({
            createOrder: function(data, actions) {
                console.log(data);
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            // e.g reference.price
                            value: reference.value.price,
                        },
                    }],
                });
            },
            onApprove: function(data, actions) {
                console.log(data);
                return actions.order.capture().then(function(orderData) {
                    console.log(orderData);
                    // Successful capture!
                    onApproveR(orderData);
                    // e.g. Inertia.post(route('order.update', reference.orderId)
                });
            },
            onError: (err) => {
                onErrorR(err);
                console.error('An error prevented the buyer from checking out with PayPal');
            },
            style: {
                // Adapt to your needs
                layout: 'vertical',
                color: 'gold',
                shape: 'rect',
                label: 'paypal',
            },
            // The following is optional and you can
            // limit the buttons to those you want to
            // provide
            fundingSource: paypal.FUNDING.PAYPAL,
        }).render('#paypal-button-container');
    } catch (error) {
        // Add proper error handling
        console.error(error);
    }
});
</script>

<template>
    <div id="paypal-button-container"></div>
</template>