<template>
    <main class="main">
        <div class="breadcrumb-wrap ofertas-bg">
            <div class="banner3"
                style="background: url('/assets/images/bg/bg-header-001.jpg'); background-color:#1d233d">
                <div class="container-lg">
                    <div class="breadcrumb-box">
                        <div class="heading-box hero-img">
                            <h1>Nuestra Historia</h1>
                            <img src="/assets/images/brands/zeta-brands.svg" alt="Ofertas">
                        </div>


                    </div>
                </div>
            </div>
        </div>

        <section class="about-section">
            <div class="container">
                <div class="row">
                    <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                        <div class="inner-column">
                            <div class="sec-title">
                                <span class="title">Zona Digital</span>
                                <h2>Un Legado de Tecnología y Superación</h2>
                            </div>
                            <div class="text">En el año 2006, en la pintoresca ciudad de Ahuachapán, nació un sueño de
                                superación y
                                emprendimiento que cambiaría la forma en que los salvadoreños interactúan con la
                                tecnología.
                                Este sueño se materializó en la forma de Zona Digital, un lugar que comenzó como una
                                modesta
                                sala de juegos que ofrecía consolas y computadoras personales. En aquellos primeros
                                días, la idea
                                de un espacio de entretenimiento digital era todavía una rareza y enfrentó desafíos
                                financieros
                                significativos</div>


                            <div class="text">
                                Sin embargo, lo que distinguió a Zona Digital desde el principio fue su compromiso con
                                la
                                innovación y la satisfacción del cliente. A medida que los meses pasaban, el negocio
                                comenzó a
                                diversificarse y se agregaron una pequeña tienda de accesorios tecnológicos,
                                convirtiendo a Zona
                                Digital en un destino completo para los amantes de la tecnología. Computadoras y
                                videojuegos se
                                convirtieron en nuestros productos estrella, y con el tiempo, se sumó un nuevo servicio
                                revolucionario: el acceso a Internet de alta velocidad.
                            </div>


                        </div>
                    </div>

                    <!-- Image Column -->
                    <div class="image-column col-lg-6 col-md-12 col-sm-12">
                        <div class="inner-column wow fadeInLeft">
                            <figure class="image-1"><img src="https://images.unsplash.com/photo-1535223289827-42f1e9919769?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHRpZW5kYSUyMGRlJTIwdGVjaHxlbnwwfHwwfHx8MA%3D%3D" alt="">
                            </figure>
                            <figure class="image-2"><img src="https://plus.unsplash.com/premium_photo-1661608128429-3fcf007899ae?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8c3RvcmUlMjBkaWdpdGFsfGVufDB8fDB8fHww" alt="">
                            </figure>
                        </div>
                    </div>
                </div>
            </div>



            <div class="container">
                <div class="row">

                    <div class="content-column col-lg-6 col-md-12 col-sm-12">
                        <div class="inner-column">

                            <div class="text">El año 2012 marcó un hito significativo en la historia de Zona Digital, ya
                                que se formalizó como empresa a través de una estratégica alianza en la capital de El
                                Salvador. Este paso
                                crucial permitió que la Zona Digital expandiera sus horizontes y abriera sus puertas en
                                el reconocido
                                centro comercial Plaza Merliot. A partir de entonces, Zona Digital dejó de ser una mera
                                tienda
                                y se convirtió en un ícono para los clientes gamers de El Salvador.
                                <br> <br>
                                El éxito de Zona Digital no ha sido fortuito, sino el resultado de un incansable
                                esfuerzo, la lealtad de nuestros clientes y una dedicación inquebrantable a la
                                excelencia en el servicio al
                                cliente. Además, no podemos dejar de reconocer la importancia de la perseverancia y la
                                guía
                                divina, que han sido piedras angulares de nuestro crecimiento constante.
                                <br> <br>
                                Hoy en día, la historia de Zona Digital sigue escribiéndose, y nuestra visión es clara:
                                ser una empresa que ofrezca soluciones y novedades en el siempre cambiante mundo de la
                                tecnología. Nos esforzamos día a día para marcar la diferencia en el mercado
                                salvadoreño, brindando
                                productos de calidad y servicios excepcionales a precios competitivos.
                                <br> <br>
                                A lo largo de los años, Zona Digital ha pasado de ser un pequeño sueño a convertirse en
                                un referente en el ámbito de la tecnología en El Salvador. Nuestro compromiso con la
                                innovación, la satisfacción del cliente y la búsqueda constante de la excelencia nos
                                impulsa a seguir
                                creciendo y a servir a todos los salvadoreños con lo mejor en tecnología. La historia de
                                Zona Digital
                                continúa, y estamos emocionados por lo que el futuro nos depara en este emocionante
                                viaje
                                tecnológico.
                            </div>
                        </div>
                    </div>

                    <!-- Image Column -->
                    <div class="image-column col-lg-6 col-md-12 col-sm-12">

                        <div class="container gallery-historia">

                                <img src="https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" class="img-fluid rounded">
                      
                       
                                <img src="https://plus.unsplash.com/premium_photo-1661727547850-3d7c020a64a8?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mzd8fHRlY2hub2xvZ3l8ZW58MHx8MHx8fDA%3D" class="img-fluid rounded">
                        

                                <img src="https://images.unsplash.com/photo-1504384764586-bb4cdc1707b0?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDZ8fHRlY2hub2xvZ3l8ZW58MHx8MHx8fDA%3D" class="img-fluid rounded">
                         
                      
                                <img src="https://images.unsplash.com/photo-1614624532983-4ce03382d63d?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTYzfHx0ZWNobm9sb2d5fGVufDB8fDB8fHww" class="img-fluid rounded">
                         

                                <img src="https://images.unsplash.com/photo-1593508512255-86ab42a8e620?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTg0fHx0ZWNobm9sb2d5fGVufDB8fDB8fHww" class="img-fluid rounded">
                    
                                <img src="https://images.unsplash.com/photo-1612287230202-1ff1d85d1bdf?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjE2fHx0ZWNobm9sb2d5fGVufDB8fDB8fHww" class="img-fluid rounded">
                         
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </main>
</template>
<script>
export default {
    name: 'HistoriaSection'
}
</script>