<template>
    <div>
        <!-- Brands -->
    <main class="main">
        <div class="breadcrumb-wrap">
            <div class="banner3" style="background: url('/assets/images/bg/bg-header-001.jpg'); background-color:#1d233d">
                <div class="container-lg">
                    <div class="breadcrumb-box">
                        <div class="heading-box hero-img">
                            <h1>Las mejores marcas <br> para todos los gustos y necesidades</h1>
                            <img src="/assets/images/brands/zeta-brands.svg" alt="Ofertas">
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <section class="shop-page">
            <div class="container-lg">
                <div class="title-marca">
                    <!-- <h1>Marcas destacadas</h1> -->
                </div>
                
                <div class="brands-product py-3">
                    <template v-for="(banner, index) in banners" :key="index">
                        <div class="brands-left" :style="{'background-color': banner.color_tag ? banner.color_tag  : '#535c85'}" >
                            <div>
                                <div class="logo-brand" v-if="banner.imagen_marca">
                                    <a href=""> <img :src="banner.imagen_marca" alt=""></a>
                                </div>
                                <div>
                                    <h3>{{banner.title}}</h3>
                                    <p>{{banner.summary}} </p>
                                    <button class="see-more">
                                        <a :href="banner.links">Ver todos ➜</a>
                                    </button>
                                </div>
                            </div>
                            <div class="image-left">
                                <img :src="banner.imagen" alt="">
                            </div>
                        </div>
                    </template>
                </div>

                <!-- brands-fav -->
                <div class="">
                    <div class="container">
                        <div class="row justify-content">
                            <div class="col-12">

                                <div class="shop-product">
                                    <div class="top-header-wrap">
                                        
                                        <div class="grid-option-wrap">
                                            <div class="select-options">
                                                <div class="select-menu">
                                                    <div class="dropdown select-dropdown small-dropdown">
                                                        <button class="select-showval" id="featureProduct"><span>{{ SELECT_TYPE }}</span> <i data-feather="chevron-down"></i></button>
                                                        <ul class="onhover-show-div select-vallist">
                                                            <li class="select-list" @click="selectType(1)">Marcas Hot</li>
                                                            <li class="select-list" @click="selectType(2)">Recomendadas</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                </div>
                                

                            </div>
                            <div class="col-xl-12 col-md-12 col-sm-12 col-12" v-if="marcas_destacadas.length > 0">
                                <!-- <Carousel v-bind="settings" :breakpoints="breakpointsOffert">
                                    <Slide v-for="(marcas_destac, index) in marcas_destacadas"
                                        :key="index">
                                        <div class="carousel__item">
                                            <div class="brand-item" >
                                                <div class="mask-brand" :style="{'background': marcas_destac.color_tag}">
                                                    <div class="titlle-brand">
                                                        <h5><a :href="'/shop/brands/'+marcas_destac.id+'?text='+marcas_destac.name">{{marcas_destac.name}}</a></h5>
                                                        <button class="see-more">
                                                            <a :href="'/shop/brands/'+marcas_destac.id+'?text='+marcas_destac.name">Ver todos ➜</a>
                                                        </button>
                                                    </div>
            
                                                    <div class="brand-img">
                                                        <a :href="'/shop/brands/'+marcas_destac.id+'?text='+marcas_destac.name">
                                                            <img :src="marcas_destac.imagen" alt=""></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <template #addons>
                                        <Navigation />
                                    </template>
                                </Carousel> -->
                                <div class="swiper marca-panel-slider">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide" v-for="(marcas_destac, index) in marcas_destacadas"
                                        :key="index">
                                            <div class="carousel__item">
                                                <div class="brand-item" >
                                                    <div class="mask-brand" :style="{'background': marcas_destac.color_tag}">
                                                        <div class="titlle-brand">
                                                            <h5>
                                                                <a :href="'/shop/brands/'+marcas_destac.id+'?text='+marcas_destac.name">
                                                                {{marcas_destac.name}}</a>
                                                            </h5>
                                                            <button class="see-more">
                                                                <a :href="'/shop/brands/'+marcas_destac.id+'?text='+marcas_destac.name">Ver todos ➜</a>
                                                            </button>
                                                        </div>
                
                                                        <div class="brand-img">
                                                            <a :href="'/shop/brands/'+marcas_destac.id+'?text='+marcas_destac.name">
                                                                <img :src="marcas_destac.imagen" alt=""></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="container-lg">
                <div class="row gy-5 g-lg-3 g-xxl-4">
                    <div class="col-12 order-1 order-lg-2">
                        <div class="shop-product brands">
                            <div class="banner4">

                                <!-- style="background: url('/assets/images/brands/bck1.png'); background-color:#673AB7;" -->
                                
                                <div class="container-lg">
                                    <div class="search-box">
                                        <div class="brandsearch">
                                            <img src="/assets/images/brands/search-brands.svg">
                                        </div>
                                        <div class="heading-box">
                                            <h2>Encuentra productos<br> de tu marca preferida</h2>
                                        </div>
                                        <div class="search-box reverse">
                                            
                                            <input class="form-control" type="search"
                                                placeholder="Escribir marca..." v-model="search_marca" @keyup="searchMarca" @keyup.enter="listFilters"
                                                ><span class="search" @click="listFilters">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-search">
                                                    <circle cx="11" cy="11" r="8"></circle>
                                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                                </svg>
                                            </span>
                                            <br>
                                            <!-- <template v-for="(marcas_sug, index) in marcas_suggs" :key="index">
                                                <a href="#" onclick="return false;" class="text-white" style="font-weight: bold;" @click="selectedMarca(marcas_sug.name)" v-html="highlightLetters(marcas_sug.name.toUpperCase(), search_marca.toUpperCase())"></a>
                                                <br>
                                            </template> -->

                                        </div>

                                        <div class="result-marcas">
                                            <!-- <ul> -->
                                                <template v-for="(marcas_sug, index) in marcas_suggs" :key="index">
                                                    <a href="#" onclick="return false;" class="" style="font-weight: bold;" @click="selectedMarca(marcas_sug.name)" v-html="highlightLetters(marcas_sug.name.toUpperCase(), search_marca.toUpperCase())"></a>
                                                        <!-- text-white <template v-for="(chart, index2) in marcas_sug.name.split('')" :key="index2">
                                                            <template v-if="search_marca.toUpperCase().indexOf(chart.toUpperCase()) != -1 ">
                                                                <b style="background-color: #5b66ff;">{{ chart }}</b>
                                                            </template>
                                                            <template v-if="search_marca.toUpperCase().indexOf(chart.toUpperCase()) == -1 ">
                                                                {{ chart }}
                                                            </template>
                                                        </template> -->
                                                        <!-- {{ highlightLetters(marcas_sug.name.toUpperCase(), search_marca.toUpperCase()) }} -->
                                                    <br>
                                                </template>
                                                
                                            <!-- </ul> -->
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div class="top-header-wrap">


                                <!-- <button class="filter-btn btn-solid btn-sm mb-line d-flex d-lg-none">Filtro <i
                                        class="arrow"></i></button> -->

                                <div class="results-p">
                                    <p>Se han encontrado {{ total }} marcas</p>
                                </div>
                                <div class="select-options">
                                    <div class="select-menu">
                                        <div class="dropdown select-dropdown"><button class="select-showval"
                                                id="sortProduct"><span>Ordernar
                                                    por</span> <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-down">
                                                    <polyline points="6 9 12 15 18 9"></polyline>
                                                </svg></button>
                                            <ul class="onhover-show-div select-vallist">
                                                <li class="select-list" @click="tagSelect('')">Todos</li>
                                                <li class="select-list" @click="tagSelect('asc')">Nombre (A-Z)</li>
                                                <li class="select-list" @click="tagSelect('desc')">Nombre (Z-A)</li>
                                            </ul>
                                        </div>
                                        <div class="dropdown select-dropdown small-dropdown">
                                            <button class="select-showval" id="featureProduct"><span>30
                                                    Registros</span> <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-chevron-down">
                                                    <polyline points="6 9 12 15 18 9"></polyline>
                                                </svg></button>
                                            <ul class="onhover-show-div select-vallist">
                                                <li class="select-list" @click="sizePage(30)">30 Registros</li>
                                                <li class="select-list" @click="sizePage(45)">45 Registros</li>
                                                <li class="select-list" @click="sizePage(60)">60 Registros</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-tab-content">
                            <div
                                class="view-option brand-logos">
                                <template v-for="(marca, index) in marcas" :key="index">
                                    <div >
                                        <a :href="'/shop/brands/'+marca.id+'?text='+marca.name" class="brand-box"><img
                                        class="img-fluid bg-white"
                                        :src="marca.imagen"
                                        alt="brand"></a>
                                    </div>
                                </template>
                                
                            </div>

                            <div class="not-found" v-if="marcas.length == 0">
                                    <p>¡Ups! No he encontrado resultados<br> pero hay mucho más, busca algo nuevo.</p>
                                    <img src="/assets/images/product/zeta-notfound.svg">
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-12 order-1 order-lg-2 px-2 py-2">
                    <!-- <div class="pagination-wrap justify-content-center">
                        <p class="pl-0 py-2 rounded my-2"></p>
                    </div> -->
                    <PaginationG
                                    :pagination="{
                                                    per_page: per_page,
                                                    total: total,
                                                    total_pages: total_pages
                                                }"
                                    :maxVisibleButtons="maxVisibleButtons"
                                    :current-page="currentPage"
                                    @pagechanged="listFilters"/>
                </div>
            </div>
        </section>
    </main>

    </div>
</template>
<script>
import PaginationG from '../PaginationG.vue'
import myApi from '../../plugins/axios'
import { useMeta } from 'vue-meta'
// import { Carousel, Navigation, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
    name: 'FilterOffer',
    components:{
        PaginationG,
        // 
        // Carousel,
        // Slide,
        // Navigation,
    },
    data(){
        return {
            slug_product: null,
            type_selected: 1,
            marcas: [],
            marcas_suggs: [],
            // PAGINACIÓN
            currentPage:1,
            per_page:1,
            total:1,
            total_pages:1,
            maxVisibleButtons:3,
            // 
            size_page: 30,
            tag_selected: null,
            SELECT_TYPE: 'Marcas Hot',
            search_marca: null,
            // 
            marcas_destacadas: [],
            banners: [],
            // 
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpointsOffert: {
              400: {
                itemsToShow: 1,
                snapAlign: 'center',
              },
              590: {
                itemsToShow: 1,
                snapAlign: 'center',
              },
              700: {
                itemsToShow: 3,
                snapAlign: 'center',
              },
              1024: {
                itemsToShow: 3,
                snapAlign: 'center',
              },
            },
            search_marca_selected: null,
        }
    },
    methods: {
        highlightLetters(palabra, busqueda) {
            let palabraResaltada = '';
            let start = 0;
            for (let i = 0; i < palabra.length; i++) {
                if (busqueda[start] === palabra[i]) {
                    palabraResaltada += `<span style="background-color: #5b66ff;">${palabra[i]}</span>`;
                    start++;
                    if (start === busqueda.length) {
                        palabraResaltada += palabra.slice(i + 1);
                        break;
                    }
                } else {
                    palabraResaltada += palabra[i];
                }
            }
            return palabraResaltada;
        },
        sizePage(sizePage){
          this.size_page = sizePage;
          this.listFilters();
        },
        tagSelect(TAG){
          this.tag_selected = TAG;
          this.listFilters();
        },
        selectType(TYPE){
            this.type_selected = TYPE;
            this.SELECT_TYPE = TYPE == 1 ? 'Marcas Hosts' : 'Recomendadas',
            this.listFilters();
        },
        reloadMyJs() {
            const script2 = document.createElement("script");
            script2.src = "../../assets/js/feather/feather.min.js";
            document.body.appendChild(script2);
            script2.onload = () => {
                console.log("archivo.js cargado 2");
            };

            const script_myjs = document.createElement('script');
            script_myjs.src = '../../assets/js/myjs/sliderhome.js';
            document.body.appendChild(script_myjs);
            script_myjs.onload = () => {
                console.log('archivo.js cargado script_myjs');
            }
        },
        async searchMarca(){
            let data = {
                search: this.search_marca,
            }
            if(this.search_marca){
                const resp = await myApi.post(
                    "ecommerce/marcas-search",data
                );
                console.log(resp);
                this.marcas_suggs = resp.data.marcas;
            }else{
                this.marcas_suggs = [];
            }
        },
        selectedMarca(name){
            this.search_marca_selected = name;
            this.listFilters();
        },
        async listFilters(page=1){
            let data = {
                search: this.search_marca_selected ? this.search_marca_selected : this.search_marca,
                size_page: this.size_page,
                tag_selected: this.tag_selected,
                type_selected: this.type_selected,
            }
            this.currentPage = page;
            const resp = await myApi.post(
                "ecommerce/marcas/?page="+this.currentPage,data
            );
            console.log(resp);
            this.search_marca_selected = null;
            this.marcas = [];
            this.marcas = resp.data.marcas;

            this.per_page = resp.data.per_page;
            this.total = resp.data.total;
            this.total_pages = resp.data.total_pages;
            this.maxVisibleButtons = this.total_pages > 4 ? 3 : this.total_pages;

            this.marcas_destacadas = resp.data.marcas_destacadas;
            if(this.marcas_destacadas.length < 4 && this.marcas_destacadas.length > 1){
                this.marcas_destacadas.forEach(item => {
                    this.marcas_destacadas.push(item);
                })
            }
            // if(this.marcas_destacadas.length <= 1 ){
            //     let MARC_D = this.marcas_destacadas;
            //     this.marcas_destacadas = [];
            //     setTimeout(() => {
            //         this.marcas_destacadas = MARC_D;
            //     }, 50);
            //     this.breakpointsOffert = {
            //         590: {
            //             itemsToShow: 1,
            //             snapAlign: 'center',
            //         },
            //         700: {
            //             itemsToShow: 1,
            //             snapAlign: 'center',
            //         },
            //         1024: {
            //             itemsToShow: 1,
            //             snapAlign: 'center',
            //         },
            //     };
            // }
            this.banners = resp.data.banners;
            setTimeout(() => {
                this.reloadMyJs()
            }, 50);
        },
        makeTitle(slug) {
            var words = slug.split('-');
            for (var i = 0; i < words.length; i++) {
                var word = words[i];
                words[i] = word.charAt(0).toUpperCase() + word.slice(1);
            }
            return words.join(' ');
        },
        actualizarMetaetiquetas(titulo, descripcion, imagen,url) {
        const ogTitle = document.querySelector('meta[property="og:title"]');
        const ogDescription = document.querySelector('meta[property="og:description"]');
        const ogImage = document.querySelector('meta[property="og:image"]');
        const ogUrl = document.querySelector('meta[property="og:url"]');
            ogTitle.content = titulo;
            ogDescription.content = descripcion ? descripcion : '';
            if(imagen){
                ogImage.content = imagen;
            }
            ogUrl.content = url;
        },
    },
    created(){
    },
    mounted(){
        this.listFilters();
        this.useMetaT = useMeta({
            title: 'Marcas destacadas',
            htmlAttrs: { lang: 'en', amp: true },
            meta: [],
        })
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 150);
    }
}
</script>
<style>
  .brands-fav{
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columnas con igual tamaño */
    grid-gap: 10px;

    /* Media Query para tableta */
    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr); /* 2 columnas con igual tamaño en tableta */
    }

    /* Media Query para celular */
    @media (max-width: 480px) {
        grid-template-columns: 1fr; /* 1 columna ocupando todo el ancho en celular */
    }
  }

  .carousel__slide {
    display: block
  }

  @media (max-width: 767px) {
      .brand-item {
          height: 155px;
      }
      .carousel__next {
          right: -30px;
          top: 85px;
      }
      .carousel__prev {
          left: -30px;
          top: 85px;
      }
      .titlle-brand h5 a {
          font-size: 15px;
      }
      .brand-img img {
          width: 100%;
          height: 100%;
          object-fit: contain;
      }
      .mask-brand {
          padding: 15px;
      }
      button.see-more {
          font-size: 13px;
      }
      button.see-more::after {
          width: 77px;
      }
  }
</style>