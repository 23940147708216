<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <div class="breadcrumb-wrap">

                <div class="breadcrumb-wrap"  v-if="categorie_selected">
                    <div class="banner" id="bannerId">
                        <!-- <img class="bg-img bg-top" :src="categorie_selected.image" alt="banner" /> -->

                        <div class="container-lg">
                            <div class="breadcrumb-box">
                            <div class="heading-box">
                                <h1 v-if="!categorie_selected_add">{{ slug_product }}</h1>
                                <h1 v-if="categorie_selected_add">{{ categorie_selected_add.name }}</h1>
                            </div>
                            <ol class="breadcrumb">
                                <li><a href="/">Inicio</a></li>
                                <li>
                                <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                </li>
                                <li class="current">
                                    <a :href="'/shop/categorie/'+categorie_selected.id+'?text='+getNameFirstCateg()">
                                        {{ getNameFirstCateg() }}
                                    </a>
                                </li>
                                
                                <template v-if="categorie_second_id">
                                    <li>
                                    <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                    </li>
                                    <li class="current"><a :href="'/shop/categorie/'+categorie_selected.id
                                    +'?text='+getNameSecondCateg()
                                    +'&categorie_second_id='+categorie_second_id
                                    ">{{ getNameSecondCateg() }}</a></li>
                                </template>
                                <template v-if="categorie_s_second_id">
                                    <li>
                                    <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                    </li>
                                    <li class="current"><a :href="'/shop/categorie/'+categorie_selected.id
                                    +'?text='+getNameThreeSecondCateg()
                                    +'&categorie_second_id='+categorie_s_second_id
                                    ">{{ getNameThreeSecondCateg() }}</a></li>
                                </template>
                                <template v-if="categorie_third_id">
                                    <li>
                                    <a href="javascript:void(0)"><i data-feather="chevron-right"></i></a>
                                    </li>
                                    <li class="current"><a :href="'/shop/categorie/'+categorie_selected.id
                                    +'?text='+getNameThreeCateg()
                                    +'&categorie_third_id='+categorie_third_id
                                    +'&categorie_s_second_id='+categorie_s_second_id
                                    ">{{ getNameThreeCateg() }}</a></li>
                                </template>
                            </ol>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!--  -->
            <div v-if="!categorie_selected">
                <img :src="'/assets/svg_load/banner-preloader.svg'" alt="Icono SVG" />
            </div>
            <!-- Breadcrumb End -->
            <FilterProduct 
                    :categorie_id="categorie_id" 
                    :categorie_second_id="categorie_second_id"
                    :categorie_third_id="categorie_third_id"
                    :categorie_s_second_id="categorie_s_second_id"
                    :categorie_selected_external="categorie_selected"
                    :slug_categorie="slug_product" :is_tienda="1"></FilterProduct>
        </main>
        <!-- Main End -->

    </div>
</template>
<script>
import FilterProduct from './layouts/FilterProduct.vue'
// import myApi from '../../plugins/axios'
import { useMeta } from 'vue-meta'

export default {
    name: 'FilterSearch',
    components:{
        FilterProduct,
    },
    data(){
        return {
            slug_product: null,
            categorie_id: null,
            categorie_selected: null,
            // 
            categorie_selected_add: null,
            // 
            categorie_second_id: null,
            categorie_third_id: null,
            categorie_s_second_id: null,
            // 
            jsonData: null,
            categories_: [],
        }
    },
    methods: {
        async loadJSON() {
            try {
                const response = await fetch("/assets/json/banner-preloader.json"); // Ruta al archivo JSON
                const data = await response.json();
                this.jsonData = data;
            } catch (error) {
                console.error("Error al cargar el JSON:", error);
            }
        },
        getWithExpiry(key) {
            const itemStr = localStorage.getItem(key)
            if (!itemStr) {
                return null
            }
            const item = JSON.parse(itemStr)
            const now = new Date()
            if (now.getTime() > item.expiry) {
                localStorage.removeItem(key)
                return null
            }
            return item.value
        },
        makeTitle(slug) {
            var words = slug.split('-');
            for (var i = 0; i < words.length; i++) {
                var word = words[i];
                words[i] = word.charAt(0).toUpperCase() + word.slice(1);
            }
            return words.join(' ');
        },
        getNameFirstCateg(){
            let CATEGORIE = this.categories_.find((item) => item.id == this.categorie_id);
            if(CATEGORIE){
                // setTimeout(() => {
                //     document.getElementById("bannerId").style.backgroundColor=CATEGORIE.color_tag ? CATEGORIE.color_tag : 'blue';
                // }, 500);
                return CATEGORIE.name;
            }
            return this.slug_product;
        },
        getNameSecondCateg(){
            let DEPARTAMENT = this.categories_.find((item) => item.id == this.categorie_id);
            if(DEPARTAMENT){
                let CATEGORIE = DEPARTAMENT.categories.find((item) => item.id == this.categorie_second_id);
                if(CATEGORIE){
                    return CATEGORIE.name;
                }
            }
            return this.slug_product;
        },
        getNameThreeCateg(){
            let DEPARTAMENT = this.categories_.find((item) => item.id == this.categorie_id);
            if(DEPARTAMENT){
                let CATEGORIE =  DEPARTAMENT.categories.find((item) => item.id == this.categorie_s_second_id);
                if(CATEGORIE){
                    let SUNCATEGORIE = CATEGORIE.categories.find((item) => item.id == this.categorie_third_id);
                    if(SUNCATEGORIE){
                        return SUNCATEGORIE.name;
                    }
                }
            }
            return this.slug_product;
        },
        getNameThreeSecondCateg(){
            let DEPARTAMENT = this.categories_.find((item) => item.id == this.categorie_id);
            if(DEPARTAMENT){
                let CATEGORIE = DEPARTAMENT.categories.find((item) => item.id == this.categorie_s_second_id);
                if(CATEGORIE){
                    return CATEGORIE.name;
                }
            }
            return this.slug_product;
        },
    },
    created(){
        this.slug_product = this.$route.query.text;
        this.categorie_id = this.$route.params.id;

        this.categorie_selected = this.getWithExpiry('categorie_shop_'+this.categorie_id);
        if(this.categorie_selected){
            setTimeout(() => {
                    document.getElementById("bannerId").style.backgroundColor=this.categorie_selected.color_tag ? this.categorie_selected.color_tag : 'blue';
                    document.getElementById("bannerId").style.backgroundImage = "url("+this.categorie_selected.image+")";
                    document.getElementById("bannerId").style.backgroundSize = "cover";
                    document.getElementById("bannerId").style.display = "block";
                    document.getElementById("bannerId").style.backgroundRepeat = "no-repeat";
            }, 500);
        }
        this.categorie_second_id = this.$route.query.categorie_second_id;
        this.categorie_third_id = this.$route.query.categorie_third_id;

        this.categorie_s_second_id = this.$route.query.categorie_s_second_id;

    },
    mounted(){
        let TITLE_F = this.makeTitle(this.slug_product);
        const now = new Date();
        this.useMetaT = useMeta({
            title: TITLE_F,
            htmlAttrs: { lang: 'en', amp: true },
            meta: [],
        })
        if(!this.categorie_selected){
            this.emitter.on('categorie-selected',data => {
                this.categorie_selected = data;
                const item = {
                    value: this.categorie_selected,
                    expiry: now.getTime() + 3600000,
                }
                localStorage.setItem('categorie_shop_'+this.categorie_selected.id, JSON.stringify(item))
                
                setTimeout(() => {
                    let local_dinamics = localStorage.getItem("local_dinamic") || JSON.stringify([]);
                    local_dinamics = JSON.parse(local_dinamics);
                    local_dinamics.push('categorie_shop_'+this.categorie_selected.id)
                    localStorage.setItem("local_dinamic",JSON.stringify(local_dinamics))

                    document.getElementById("bannerId").style.backgroundColor=this.categorie_selected.color_tag ? this.categorie_selected.color_tag : 'blue';
                    document.getElementById("bannerId").style.backgroundImage = "url("+this.categorie_selected.image+")";
                    document.getElementById("bannerId").style.backgroundSize = "cover";
                    document.getElementById("bannerId").style.display = "block";
                    document.getElementById("bannerId").style.backgroundRepeat = "no-repeat";
                }, 500);
            });
        }

        let CATEGORIES = localStorage.getItem("departaments") ? JSON.parse(localStorage.getItem("departaments")) : [];
        this.categories_ = CATEGORIES;
        // if(this.categorie_second_id || this.categorie_third_id){
        //     this.emitter.on('categorie-selected-add',data => {
        //         this.categorie_selected_add = data;
        //     });
        // }
    }
}
</script>