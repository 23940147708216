<template>
    <div class="pagination-wrap justify-content-center">
        <p class="pl-0 py-2 rounded my-2">
            <!-- Ver {{ (currentPage - 1) * per_page + 1 }} a {{ currentPage * per_page }} de {{ total }} registros -->
        </p>

        <ul v-if="total_pages > 1" class="pagination">
            <!-- <li class="border-r-0 ml-0 rounded-l hover:text-white hover:bg-primary" :class="{'bg-primary-light': isInFirstPage}">
                <a href="javascript:void(0)"
                        class="py-2 px-3 font-semibold"
                        :class="{'cursor-not-allowed': isInFirstPage}"
                        :disabled="isInFirstPage"
                        @click="gotoFirst">
                    &laquo;
                </a>
            </li>

            <li class="border-r-0 hover:text-white hover:bg-primary" :class="{'bg-primary-light': isInFirstPage}">
                <a href="javascript:void(0)"
                        class="py-2 px-3 font-semibold"
                        :class="{'cursor-not-allowed': isInFirstPage}"
                        :disabled="isInFirstPage"
                        @click="gotoPrevious">
                    &lsaquo;
                </a>
            </li> -->

            <template v-if="showDots('left')">
                <li class="border-r-0 hover:text-white hover:bg-primary" :class="{'bg-primary-q': isInFirstPage}">
                    <a href="javascript:void(0)"
                        class="py-2 px-3 font-semibold"
                        :class="{'cursor-not-allowed': isInFirstPage}"
                            :disabled="isInFirstPage"
                            @click="gotoPageNumber(1)">
                        1
                    </a>
                </li>

                <li class="border-r-0 hover:text-white hover:bg-primary">
                    <a href="javascript:void(0)"
                        class="py-2 px-3 font-semibold"
                            :disabled="true">
                        ...
                    </a>
                </li>
            </template>

            <li class="border-r-0 hover:text-white hover:bg-primary" v-for="(page, index) in pages"
                :key="`pages_${index}`"
                :class="{'bg-primary-q': page === currentPage,'text-white': page === currentPage}">
                <a href="javascript:void(0)"
                        class="py-2 px-3 font-semibold"
                        :class="{'active': page === currentPage}"
                        :disabled="page === currentPage"
                        @click="gotoPageNumber(page)">
                    {{ page }}
                </a>
            </li>

            <template v-if="showDots('right')">
                <li class="border-r-0 hover:text-white hover:bg-primary">
                    <a href="javascript:void(0)"
                        class="py-2 px-3 font-semibold"
                            :disabled="true">
                        ...
                    </a>
                </li>

                <li class="border-r-0 hover:text-white hover:bg-primary" :class="{'bg-primary-q': isInLastPage}">
                    <a href="javascript:void(0)"
                        class="py-2 px-3 font-semibold"
                        :class="{'cursor-not-allowed': isInLastPage}"
                            :disabled="isInLastPage"
                            @click="gotoPageNumber(total_pages)">
                        {{ total_pages }}
                    </a>
                </li>
            </template>

            <!-- <li class="border-r-0 hover:text-white hover:bg-primary" :class="{'bg-primary-light': isInLastPage}">
                <a href="javascript:void(0)"
                        class="py-2 px-3 font-semibold"
                        :class="{'cursor-not-allowed': isInLastPage}"
                        :disabled="isInLastPage"
                        @click="gotoNext">
                    &rsaquo;
                </a>
            </li>

            <li class="border-r-0 rounded-r border-r hover:text-white hover:bg-primary" :class="{'bg-primary-light': isInLastPage}">
                <a href="javascript:void(0)"
                        class="py-2 px-3 font-semibold"
                        :class="{'cursor-not-allowed': isInLastPage}"
                        :disabled="isInLastPage"
                        @click="gotoLast">
                    &raquo;
                </a>
            </li> -->
        </ul>
    </div>
</template>

<script>

export default {
    name:'PaginationG',
    props: {
        currentPage: { type: Number, required: true, default: 1 },
        pagination: { type: Object, required: true, default: () => ({}) },
        maxVisibleButtons: { type: Number, required: false, default: 3 },
    },
    data: () => ({
        per_page: 10,
        total: 0,
        total_pages: 0,
    }),

    watch: {
        pagination: {
            handler(pagination) {
                this.per_page = pagination.per_page || 10
                this.total = pagination.total || 0
                this.total_pages = pagination.total_pages || 0
            },
            immediate: true,
        },
    },

    computed: {
        isInFirstPage() {
            return this.currentPage === 1
        },

        isInLastPage() {
            return this.currentPage === this.total_pages
        },

        pages() {
            const range = []
            for (let i = this.startPage; i <= this.endPage; i += 1) {
                range.push(i)
            }

            return range
        },

        startPage() {
            if (this.currentPage === 1) {
                return 1
            }

            if (this.currentPage === this.total_pages) {
                return this.total_pages - this.maxVisibleButtons + 1
            }
            return this.currentPage - 1
        },

        endPage() {
            return Math.min(this.startPage + this.maxVisibleButtons - 1, this.total_pages)
        },
    },

    methods: {
        showDots(position = "left") {
            const number = position === "left" ? 1 : this.total_pages
            const nextNumber = position === "left" ? 2 : this.total_pages - 1

            return !this.pages.includes(number) || !this.pages.includes(nextNumber)
        },
        scrollTop(){
            setTimeout(() => {
                var secondElement = document.querySelector(".grid-option-wrap");
                var offsetTop = secondElement ? secondElement.offsetTop - 15 : 350;
                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth'
                });
            }, 650);
        },
        gotoFirst() {
            this.gotoPageNumber(1)
            this.scrollTop();
        },

        gotoLast() {
            this.gotoPageNumber(this.total_pages)
            this.scrollTop();
        },

        gotoPrevious() {
            this.gotoPageNumber(this.currentPage - 1)
            this.scrollTop();
        },

        gotoNext() {
            this.gotoPageNumber(this.currentPage + 1)
            this.scrollTop();
        },

        gotoPageNumber(pageNumber) {
            this.$emit("pagechanged", pageNumber)
            this.scrollTop();
        },
    },

    created(){
        // setTimeout(() => {
        //     this.maxVisibleButtons = this.total_pages > 4 ? 5 : this.total_pages;
        // }, 100);
    }
}
</script>
<style>
    .bg-primary-q{
        border-radius: 5px !important;
    }
</style>