<template>
    <div>
        <!-- Main Start -->
        <main class="main">
            <!-- Breadcrumb Start -->
            <div class="breadcrumb-wrap">
                <div class="banner">
                <img class="bg-img bg-top" src="/assets/images/bg/bg-header-001.jpg" alt="banner" />

                <div class="container-lg">
                    <div class="breadcrumb-box">
                        <div class="heading-box">
                            <h1> 🔥 Los Más Hot De Zona </h1>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <!-- Breadcrumb End -->
            <FilterProduct :is_best_sellers="is_best_sellers"></FilterProduct>
        </main>
        <!-- Main End -->

    </div>
</template>
<script>
import FilterProduct from './layouts/FilterProduct.vue'
import { useMeta } from 'vue-meta'

export default {
    name: 'FilterOffer',
    components:{
        FilterProduct,
    },
    data(){
        return {
            is_best_sellers: 1,
        }
    },
    methods: {
    },
    created(){
    },
    mounted(){
        this.useMetaT = useMeta({
            title: '🔥 Los Más Hot De Zona',
            htmlAttrs: { lang: 'en', amp: true },
            meta: [],
        })
    }
}
</script>