<template>
    <div>
        <div class="banner-thank">
            <div class="container-lg">
                <div class="breadcrumb-box">
                    <div class="heading-box">
                    <h3 class="animate-charcter">¡Gracias por tu compra!</h3>
                        <!-- <img src="/assets/images/logos/logo-w.png" alt=""> -->
                        <img class="logo logo-light-thank" src="/assets/images/logos/logo-w.png" alt="logo" />
                        <img class="logo logo-dark-thank" src="/assets/images/logos/logo.png" alt="logo" />

                    </div>
                </div>
            </div>
        </div>

        <div class="thank-you" v-if="ORDER_SELECTED">
            <div id="thank-you-page">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h3>Tu orden ha sido recibida</h3>

                            <div class="order-summary">
                                <!-- <div class="order-summary-item">
                                    <h2 class="text-nowrap">Número de orden</h2>
                                    <p>{{ ORDER_SELECTED.order_id_external ? ORDER_SELECTED.order_id_external : ORDER_SELECTED.id }} </p>
                                </div> -->
                                <div class="order-summary-item">
                                    <h2>Fecha</h2>
                                    <p class="text-nowrap">{{ ORDER_SELECTED.created_at }}</p>
                                </div>
                                <div class="order-summary-item">
                                    <h2>Total</h2>
                                    <p>${{ ORDER_SELECTED.total }}</p>
                                </div>
                                <div class="order-summary-item no-border">
                                    <h2>Método de pago</h2>
                                    <p v-if="ORDER_SELECTED.type_payment == 1" class="text-nowrap"> Transferencia
                                    </p>
                                    <p v-if="ORDER_SELECTED.type_payment == 2" class="text-nowrap">Tarjeta
                                    </p>
                                    <p v-if="ORDER_SELECTED.type_payment == 3" class="text-nowrap">Paypal
                                    </p>
                                    <p v-if="ORDER_SELECTED.type_payment == 4" class="text-nowrap">Contra-Entrega
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="alert alert-info alert-with-icon padding-alert" role="alert" v-if="ORDER_SELECTED.type_entrega == 2">
                        <div class="aviso-flex">
                            <div class="alert-icon-box">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-alert-triangle alert-icon">
                                    <path
                                        d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z">
                                    </path>
                                    <line x1="12" y1="9" x2="12" y2="13"></line>
                                    <line x1="12" y1="17" x2="12.01" y2="17"></line>
                                </svg>
                            </div>
                            <div><b><i class="fa fa-warning fa-lg"></i>Información de entrega</b></div>
                        </div>

                        <div class="texto-entrega py-3" v-if="today">
                            <p>¡Entrega apróximada <span class="bold-date">{{ next_name_day + ' ' +next_num_day }} a {{ next_name_two_day }} {{ next_num_two_day }} de {{ next_num_month }}
                                </span> 
                                <!-- si ordenas hoy
                                <span class="bold-date">{{today}}!</span> -->
                            </p>
                        </div>
                        <div class="btn-ws">
                        <a href="https://wa.me/1234567890" class=" btn-green">
                            <img src="/assets/images/checkout/whatsapp.png" style="width: 20px;height: 100%;">
                            Contáctanos para mayor información</a>
                        </div>

                        <div class="btn-tracking">
                            <div><b>Ver tracking del pedido:</b>
                            </div>
                            <a :href="'/tracking-pedido/'+ORDER_SELECTED.uniqd" target="_blank" class=" btn-green">
                            Ver aquí</a>
                        </div>
                    </div>

                    <div class="alert alert-info alert-with-icon padding-alert" role="alert" v-if="ORDER_SELECTED.type_entrega == 1">
                        <div class="direccion">
                            <h3>Haz seleccionado pasar a recoger tu orden a la sucursal:</h3>
                            <!-- <img src="/assets/images/product/descarga_2_B.png" v-if="ORDER_SELECTED.sucursal == 'Metrocentro'"  alt="Metrocentro" title="Metrocentro">
                            <img src="/assets/images/product/descarga_1_A.png" v-if="ORDER_SELECTED.sucursal == 'Plaza Merliot'"  alt="Plaza Merliot" title="Plaza Merliot">
                            <img src="/assets/images/product/descarga_3_V.png" v-if="ORDER_SELECTED.sucursal == 'Plaza Mundo'"  alt="Plaza Mundo" title="Plaza Mundo">
                            <p>{{ ORDER_SELECTED.sucursal }}</p> -->
                        </div>

                        <div class="horario-atencion">
                            <div class="horario-item" v-if="ORDER_SELECTED.sucursal == 'Plaza Merliot'">
                                <div class="des-suc">
                                    <img src="/assets/images/product/descarga_1_A.png" alt="Plaza Merliot" title="Plaza Merliot">
                                    <h3>Plaza Merliot</h3>
                                    <li>
                                        <svg fill="#3b73f1" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" class="reloj">
                                            <path fill="#3b73f1"
                                                d="M8 1.5a6.5 6.5 0 1 0 6.5 6.5A6.5 6.5 0 0 0 8 1.5zm0 12.5a6 6 0 1 1 6-6 6 6 0 0 1-6 6z" />
                                            <path fill="#3b73f1" d="M8 4.5V8l3.25 1.9.75-1.3L9.5 7.2V4.5H8z" />
                                        </svg>
                                        Horario:
                                        <p>Lun - Sab: 9AM - 6:30PM</p>
                                        <p>Dom: 9AM - 5:30PM</p>
                                    </li>
                                </div>
                            </div>

                            <div class="horario-item" v-if="ORDER_SELECTED.sucursal == 'Plaza Mundo'">
                                <div class="des-suc">
                                    <img src="/assets/images/product/descarga_3_V.png" alt="Plaza Mundo" title="Plaza Mundo">
                                    <h3>Plaza Mundo</h3>
                        
                                    <li>
                                        <svg fill="#3b73f1" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" class="reloj">
                                            <path fill="#3b73f1"
                                                d="M8 1.5a6.5 6.5 0 1 0 6.5 6.5A6.5 6.5 0 0 0 8 1.5zm0 12.5a6 6 0 1 1 6-6 6 6 0 0 1-6 6z" />
                                            <path fill="#3b73f1" d="M8 4.5V8l3.25 1.9.75-1.3L9.5 7.2V4.5H8z" />
                                        </svg>
                                        Horario:
                                        <p>Lun - Sab: 9AM - 6:30PM</p>
                                        <p>Dom: 9AM - 5:30PM</p>
                                    </li>
                                </div>
                            </div>

                            <div class="horario-item" v-if="ORDER_SELECTED.sucursal == 'Metrocentro'">
                                <div class="des-suc">
                                    <img src="/assets/images/product/descarga_2_B.png" alt="Metrocentro" title="Metrocentro">
                                    <h3>Metro centro</h3>
                    
                                    <li>
                                        <svg fill="#3b73f1" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" class="reloj">
                                            <path fill="#3b73f1"
                                                d="M8 1.5a6.5 6.5 0 1 0 6.5 6.5A6.5 6.5 0 0 0 8 1.5zm0 12.5a6 6 0 1 1 6-6 6 6 0 0 1-6 6z" />
                                            <path fill="#3b73f1" d="M8 4.5V8l3.25 1.9.75-1.3L9.5 7.2V4.5H8z" />
                                        </svg>
                                        Horario:
                                        <p>Lun - Sab: 9AM - 6:30PM</p>
                                        <p>Dom: 9AM - 5:30PM</p>
                                    </li>
                                </div>
                            </div>
                        </div>

                        <div class="font-horario pt-3">
                            <div class="btn-ws">
                                <a href="https://wa.me/1234567890" class=" btn-green">
                                <img src="/assets/images/checkout/whatsapp.png" style="width: 20px;height: 100%;">
                                Contáctanos para mayor información</a>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <h3>Detalles del Pedido</h3>
                            <table class="bordered">
                                <thead>
                                    <tr>
                                        <th>IMAGEN</th>
                                        <th>PRODUCTO</th>
                                        <th>CANTIDAD</th>
                                        <th>PRECIO</th>
                                        <th>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(CART, index) in ORDER_SELECTED.items" :key="index">
                                        <tr>
                                            <td>  <img  v-bind:src="CART.imagen" alt="bag"></td>
                                            <td>{{CART.title}}</td>
                                            <td>{{CART.quantity}}</td>
                                            <td class="text-nowrap">
                                                <template v-if="(CART.code_cupon || CART.code_discount || CART.code_offer) && CART.discount > 0 && !CART.is_kit && !CART.is_pc_build">
                                                        <b class="color-oferta-cart">Oferta </b>
                                                </template> 
                                                $ {{CART.subtotal}}
                                                <template v-if="(CART.code_cupon || CART.code_discount || CART.code_offer) && CART.discount > 0 && !CART.is_kit && !CART.is_pc_build">
                                                    <br>
                                                    <small class="sold-by"> Antes <del><span> $ {{ CART.price_unit }} </span></del>  </small>
                                                    <!-- <br>
                                                    <small class="sold-by" v-if="CART.code_cupon && !CART.code_discount && !CART.code_offer">Cupon: <span>{{ CART.code_cupon }}</span> </small> -->
                                                </template>
                                            </td>
                                            <td class="text-nowrap">
                                                $ {{CART.total}}
                                                <template v-if="CART.discount > 0 && !CART.is_kit && !CART.is_pc_build">
                                                        <br>
                                                    <b class="color-oferta-cart">Ahorro </b>
                                                    ${{ getDescountC(CART) }}
                                                </template>
                                            </td>
                                        </tr>
                                    </template>

                                    <tr class="boder-divider">
                                        <td>Subtotal:</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>$ {{SUB_TOTAL_SUM.toFixed(2)}} </td>
                                    </tr>
                                    <tr class="">
                                        <td>Descuento:</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>$ {{DESCUENTO_TOTAL_SUM.toFixed(2)}} </td>
                                    </tr>
                                    <tr class="" v-if="groups_coupons.length > 0">
                                        <td>Cupon(es):</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <template v-for="(item, index) in groups_coupons" :key="index">
                                                {{ item }}
                                                <br>
                                            </template>
                                        </td>
                                    </tr>
                                    <tr class="boder-divider">
                                        <td>Total:</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>${{ parseFloat(ORDER_SELECTED.total)}} </td>
                                    </tr>
                                    
                                    
                                    <tr v-if="ORDER_SELECTED.type_entrega == 2">
                                        <td>Envío:</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td v-if="ORDER_SELECTED.tax > 0">${{ ORDER_SELECTED.tax }} vía Precio fijo</td>
                                        <td v-if="ORDER_SELECTED.tax <= 0">GRATIS</td>
                                    </tr>
                                    <tr v-if="ORDER_SELECTED.comision_paypal > 0">
                                        <td>Comisión Paypal:</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>$ {{ ORDER_SELECTED.comision_paypal }}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td>Método de pago:</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <p v-if="ORDER_SELECTED.type_payment == 1"> Transferencia
                                            </p>
                                            <p v-if="ORDER_SELECTED.type_payment == 2">Tarjeta
                                            </p>
                                            <p v-if="ORDER_SELECTED.type_payment == 3">Paypal
                                            </p>
                                            <p v-if="ORDER_SELECTED.type_payment == 4">Contra-Entrega
                                            </p>
                                            <small v-if="ORDER_SELECTED.type_payment == 2 && ORDER_SELECTED.sel_type_pay == 2 && ORDER_SELECTED.sel_type_pay_cuotas == 1"> 3 CUOTAS </small>
                                            <small v-if="ORDER_SELECTED.type_payment == 2 && ORDER_SELECTED.sel_type_pay == 2 && ORDER_SELECTED.sel_type_pay_cuotas == 2"> 6 CUOTAS </small>
                                            <small v-if="ORDER_SELECTED.type_payment == 2 && ORDER_SELECTED.sel_type_pay == 2 && ORDER_SELECTED.sel_type_pay_cuotas == 3"> 9 CUOTAS </small>
                                            <small v-if="ORDER_SELECTED.type_payment == 2 && ORDER_SELECTED.sel_type_pay == 2 && ORDER_SELECTED.sel_type_pay_cuotas == 4"> 12 CUOTAS </small>

                                        </td>
                                    </tr>
                                    <tr class="blue-table">
                                        <td>Total:</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>$ {{ parseFloat(ORDER_SELECTED.total).toFixed(2)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div v-if="ORDER_SELECTED.is_credito_fiscal == 1">
                        <div class="col-md-12">
                            <table class="credito-fiscal" v-if="ORDER_SELECTED.client_info">
                                <tbody>
                                    <tr>
                                        <td>¿Quiere Crédito Fiscal de la compra?</td>
                                        <td>Sí</td>
                                    </tr>
                                    <tr>
                                        <td>NIC:</td>
                                        <td>{{ ORDER_SELECTED.client_info.nit }}</td>
                                    </tr>
                                    <tr>
                                        <td>NRC:</td>
                                        <td>{{ ORDER_SELECTED.client_info.nrc }}</td>
                                    </tr>
                                    <tr>
                                        <td>Giro:</td>
                                        <td>{{ ORDER_SELECTED.client_info.giro }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="credito-fiscal" v-if="ORDER_SELECTED.client_guest">
                                <tbody>
                                    <tr>
                                        <td>¿Quiere Crédito Fiscal de la compra?</td>
                                        <td>Sí</td>
                                    </tr>
                                    <tr>
                                        <td>NIC:</td>
                                        <td>{{ ORDER_SELECTED.client_guest.nit }}</td>
                                    </tr>
                                    <tr>
                                        <td>NRC:</td>
                                        <td>{{ ORDER_SELECTED.client_guest.nrc }}</td>
                                    </tr>
                                    <tr>
                                        <td>Giro:</td>
                                        <td>{{ ORDER_SELECTED.client_guest.giro }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


            

                        <div class="envio-add" v-if="ORDER_SELECTED.type_entrega == 2">
                            <div>
                                <img src="/assets/images/checkout/delivery.png" alt="">
                            </div>
                            <h3>Dirección de envío</h3>
                            <ul v-if="ORDER_SELECTED.client_info">
                                <li>{{ ORDER_SELECTED.client_info.full_name }}</li>
                                <li> {{ ORDER_SELECTED.client_info.address }}
                                </li>
                                <br>
                                <li> {{ ORDER_SELECTED.client_info.point_reference }}</li>
                            </ul>
                            <ul v-if="ORDER_SELECTED.client_guest">
                                <li>{{ ORDER_SELECTED.client_guest.full_name }}</li>
                                <li>  {{ ORDER_SELECTED.client_guest.address }}
                                </li>
                                <br>
                                <li> {{ ORDER_SELECTED.client_guest.point_reference }}</li>

                            </ul>
                        </div>
                    

                    

                        <!-- <div class="direccion" v-if="ORDER_SELECTED.type_entrega == 1">
                            <h3>Haz Seleccionado la siguiente sucursal:</h3>

                            <img src="/assets/images/product/descarga_2_B.png" v-if="ORDER_SELECTED.sucursal == 'Metrocentro'"  alt="Metrocentro" title="Metrocentro">
                            <img src="/assets/images/product/descarga_1_A.png" v-if="ORDER_SELECTED.sucursal == 'Plaza Merliot'"  alt="Plaza Merliot" title="Plaza Merliot">
                            <img src="/assets/images/product/descarga_3_V.png" v-if="ORDER_SELECTED.sucursal == 'Plaza Mundo'"  alt="Plaza Mundo" title="Plaza Mundo">
                            <p>{{ ORDER_SELECTED.sucursal }}</p>

                        </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import myApi from '../../../plugins/axios'
export default {
    name: 'OrderSuccess',
    data(){
        return {
            CARTS: [],
            TOTAL_SUM:0,
            uniqd: null,
            ORDER_SELECTED: null,
            // 
            next_name_day: null,
            next_num_day: null,
            next_name_two_day: null,
            today: null,
            next_num_two_day: null,
            next_num_month: null,
            SUB_TOTAL_SUM: 0,
            DESCUENTO_TOTAL_SUM: 0,
            groups_coupons: [],
        }
    },
    methods:{
        getDescountC(CART){
            if(CART.discount > 0){
                return (CART.price_unit - CART.subtotal).toFixed(2);
            }
            return 0;
        },
    },
    created(){
        this.uniqd = this.$route.params.uniqd;
    },
    async mounted(){
        let CARTS = localStorage.getItem('carts') ? JSON.parse(localStorage.getItem('carts')) : [];//this.$cookies.get("carts") ? this.$cookies.get("carts") : [];
        this.CARTS = CARTS;
        this.TOTAL_SUM = this.CARTS.reduce((sum,cart) => sum+ parseFloat(cart.total),0);
        this.emitter.on('cart-shop',data => {
        this.CARTS = data;
        this.TOTAL_SUM = this.CARTS.reduce((sum,cart) => sum+ parseFloat(cart.total),0);
            // const script2 = document.createElement('script');
            // script2.src = '../../assets/js/feather/feather.min.js';
            // document.body.appendChild(script2);
            // script2.onload = () => {
            // console.log('archivo.js cargado 2');
            // }
       });

       const resp = await myApi.get('ecommerce/order_show/'+this.uniqd);
       console.log(resp);
       this.ORDER_SELECTED = resp.data.order;
       //   
       this.next_name_day = resp.data.next_name_day;
       this.next_num_day = resp.data.next_num_day;
       this.next_name_two_day = resp.data.next_name_two_day;
       this.today = resp.data.today;
       this.next_num_two_day = resp.data.next_num_two_day;
       this.next_num_month = resp.data.next_num_month;

       this.DESCUENTO_TOTAL_SUM = 0;
       this.SUB_TOTAL_SUM = 0;
       this.ORDER_SELECTED.items.forEach((cart) => {
        if(cart.discount > 0){
            this.DESCUENTO_TOTAL_SUM += (cart.price_unit - cart.subtotal);
        }
        this.SUB_TOTAL_SUM += (cart.price_unit * cart.quantity)
        if(cart.code_cupon){
            let INDEX = this.groups_coupons.findIndex((code_cupon) => code_cupon == cart.code_cupon);
            if(INDEX == -1){
                this.groups_coupons.push(cart.code_cupon);
            }
        }
       });
    },
}
</script>